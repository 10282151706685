import React, { useEffect, useMemo, useRef } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import { EMPTY_GUID } from "@iventis/utilities";
import { styled, media } from "@iventis/styles";
import { INotificationContext, NotificationContext } from "@iventis/notifications";
import { LoadingComponent } from "@iventis/components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthenticatedUser } from "@iventis/domain-model/model/authenticatedUser";
import { UserScope } from "@iventis/domain-model/model/userScope";
import { useExports } from "@iventis/api/src/exports";
import { SocketStatus, wsConnect, wsDisconnect } from "@iventis/api/src/connection.slice";
import { getProjects, removeProject } from "@iventis/permissions/src/auth.slice";
import { ProjectBilling } from "@iventis/project-settings/src/project-billing";
import { RootDispatch, RootState } from "@iventis/plans/src/state/root.store";
import useInternet from "@iventis/api/src/use-internet";
import { IventisRoute } from "@iventis/permissions/src/iventis-route";
import { useHistory } from "@iventis/subscriptions";
import { UserNotification } from "@iventis/domain-model/model/userNotification";
import { NotificationResourceData } from "@iventis/domain-model/model/notificationResourceData";
import { useIsMobileUser } from "@iventis/utilities/src/hooks/use-is-mobile";
import { setMonitoringTag, TagName } from "@iventis/observability-and-monitoring";
import HomePage from "./home";
import NavBarComponent, { NavBarComponentRef } from "./nav-bar";
import ProjectRoute from "./project.route";
import ProjectRouter from "./project.router";

// Anything inside the private router requires the user to be authenticated.
// Things like projects can be accessed here.
// There is also a link to the project router (A user can only access the project router if they have a project selected).
const PrivateRouter: React.FC = () => {
    const dispatch = useDispatch<RootDispatch>();
    const currentUser = useSelector((state: RootState) => state.auth?.user, shallowEqual);
    const selectedProjectId = useSelector((state: RootState) => state.auth.user?.projectId);
    const projects = useSelector((state: RootState) => state.auth.projects);
    const projectsLoaded = useSelector((state: RootState) => state.auth.projectsLoaded);
    const isProjectSelected = useMemo(() => selectedProjectId != null && selectedProjectId !== EMPTY_GUID, [selectedProjectId]);
    const selectedProject = useSelector((state: RootState) => state.auth.projects?.find((x) => x.id === selectedProjectId));
    const projectNeedsNaming = useSelector(() => selectedProject != null && selectedProject.id === selectedProject.name);
    const userScope = useSelector((state: RootState) => state.auth.user.userScope);
    const onboardingUrl = `/onboarding?projectId=${selectedProjectId}&firstName=${currentUser.firstName}`;

    const history = useHistory();

    const isMobileUser = useIsMobileUser();

    useEffect(() => {
        const abortController = new AbortController();
        dispatch(getProjects({ signal: abortController.signal }));
        setMonitoringTag(TagName.TOUCH_DEVICE, isMobileUser);
        return () => abortController.abort();
    }, []);

    /*
        We need to tell the navbar if an in-project route has changed. Since in project routes are a
        child route of the private router, there is no other way of updating this sensibly. The nav bar
        can internally update itself when we are on the /projects page as that is not an in-project route,
        but when we move in-project, the update has to come from inside an in-project route. This is
        necessary to not show the project logo in the /projects selector screen, and instead show the
        default iventis logo. We cannot rely on auth slice state alone since the user project is not removed
        when we return to this screen. We could remove the user project, but then we would lose the ability
        to navigate back if the user accidentally clicked the project selector.
    */
    const navBarRef = useRef<NavBarComponentRef>();

    useExports();

    const isOnboardingInitially = useRef(window.location.href.includes("onboarding"));

    useEffect(() => {
        if (isProjectSelected && projectNeedsNaming && !isOnboardingInitially.current) {
            // If we require a redirect to onboarding, because of the on going issues with our routing and having multiple browser routers,
            // a normal redirect does not actually trigger the onboarding page to render, thus we force a window reload after pushing the history
            history.push(onboardingUrl);
            isOnboardingInitially.current = true;
        }
    }, [isProjectSelected, projectNeedsNaming, isOnboardingInitially.current]);

    const notificationContext: INotificationContext = {
        createNotification: (notification: UserNotification<NotificationResourceData>) => {
            navBarRef.current.createNotification(notification);
        },
        invalidateNotifications: () => {
            navBarRef.current.invalidateNotifications();
        },
    };

    // Wait for us to load our projects before we decide on routing, prevents the project page from flashing
    if (!projectsLoaded) {
        return (
            <LoadingSpinnerContainer>
                <LoadingComponent />
            </LoadingSpinnerContainer>
        );
    }

    return (
        <StyledContainer>
            <NotificationContext.Provider value={notificationContext}>
                <div className="nav-bar">
                    <NavBarComponent
                        ref={navBarRef}
                        onLogoClicked={() => {
                            if (projects.length === 1 && userScope === UserScope.Application) {
                                // We go to the root of the application if we are a application user and have 1 project
                                history.push("/");
                            } else if (projects.length > 1 && userScope === UserScope.Application) {
                                // If we have more than one projects and we are a application user, navigate to projects
                                history.push("/projects");
                            } else {
                                // Go to the marketing site as we're not an actual user
                                const win = window.open("https://www.iventis.com", "_blank");
                                win.focus();
                            }
                            // Force reload as history changes url but not the page. This is because of our tested <BrowserRouter> tags in our router
                            // this is incorrect and should be changed! Too close to release to do this now as it will require a lot of testing as it could break
                            // navigating between pages in the app. Story created for this: 11714
                            window.location.reload();
                        }}
                    />
                </div>

                <Connection currentUser={currentUser} />
                <div id="main" role="main" style={{ position: "relative" }}>
                    <BrowserRouter>
                        <Switch>
                            <IventisRoute
                                exact
                                path="/projects/:projectId/billing"
                                render={({ match }) => {
                                    dispatch(removeProject());
                                    return <ProjectBilling projectId={match.params.projectId} />;
                                }}
                            />
                            {UserScope.Application === userScope && <IventisRoute exact path="/projects" component={HomePage} />}
                            <ProjectRoute
                                isProjectSelected={isProjectSelected}
                                path="/"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                render={(props) => <ProjectRouter {...props} />}
                                onProjectPathChanged={() => navBarRef.current.pathChanged()}
                            />
                        </Switch>
                    </BrowserRouter>
                </div>
            </NotificationContext.Provider>
        </StyledContainer>
    );
};

// Handles online status and web sockets. Should only be used once (inside private router)
const Connection: React.FC<{ currentUser: AuthenticatedUser }> = ({ currentUser }) => {
    const connectionStatus = useSelector((s: RootState) => s.connectionReducer.connectionStatus);
    const dispatch = useDispatch();
    const { isOnline } = useInternet();

    useEffect(() => {
        if (isOnline && currentUser != null && connectionStatus === SocketStatus.CLOSED) {
            dispatch(wsConnect());
        } else if (!isOnline && connectionStatus === SocketStatus.OPENED) {
            dispatch(wsDisconnect());
        }
    }, [isOnline, currentUser, connectionStatus]);

    return null;
};

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 45px auto;
    height: 100vh;
    max-height: 100vh;
    height: 100dvh;
    max-height: 100dvh;
    overflow: hidden;
    .nav-bar {
        grid-row: 1;
        grid-column: 1 / span 2;
    }
    #main {
        grid-column: auto;
        grid-row: 2;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    ${media.extraSmall} {
        grid-template-columns: 100%;
        grid-template-rows: 45px auto;
        .nav-bar {
            grid-row: 1;
        }
        #main {
            grid-row: 2;
            grid-column: 1;
        }
    }
`;

const LoadingSpinnerContainer = styled.div`
    height: 100vh;
    width: 100vw;
    height: 100dvh;
    width: 100dvw;
`;
export default PrivateRouter;
