/* eslint-disable iventis/use-iventis-useHistory */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */
import { IsoCurrencyCode } from "@iventis/domain-model/model/isoCurrencyCode";
import { ProjectPaymentMethod } from "@iventis/domain-model/model/projectPaymentMethod";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { SubscriptionProration } from "@iventis/domain-model/model/subscriptionProration";
import { isValidDate, useObservableValue } from "@iventis/utilities";
import { createContext, useContext } from "react";
import { BehaviorSubject } from "rxjs";
import { v4 as uuid } from "uuid";
import formatISO from "date-fns/formatISO";
import { PublicSubscriptionPlans } from "@iventis/domain-model/model/publicSubscriptionPlans";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import { STAGE_QUERY_NAME, subscriptionDetailsStages, SUBSCRIPTION_UPDATED_QUERY_NAME, VIEW_PLANS_QUERY_NAME } from "./subscription-wizard-constants";
import { SubscriptionPlanTier } from "./subscription-plan-details";
import { defaultPrice, getMatchingPrice, getMaximumUsersAllowedForTier, readSubscriptionFromUrl, mockUrlProjectSubscription } from "./subscription-wizard-helpers";
import { useHistory } from "./use-history";

export type SubscriptionWizardServices = {
    getPublicPlans: (projectId: string) => Promise<{ publicSubscriptionPlans: PublicSubscriptionPlans; allPlans: SubscriptionPlan[] }>;
    getStripeUrl: (projectSubscription: ProjectSubscription, backUrl: string, completeUrl: string) => Promise<string>;
    /**
     *  @deprecated
     * */
    getCurrentPaymentMethod: (projectId: string) => Promise<ProjectPaymentMethod>;
    /**
     *  @deprecated
     * */
    updateSubscription: (sub: ProjectSubscription, plan: SubscriptionPlan) => Promise<void>;
    /**
     *  @deprecated
     * */
    getProrationInfo: (subscriptionId: string, priceId: string, quantity: number) => Promise<SubscriptionProration>;
};

export type SubscriptionWizardData = {
    paymentMethod: ProjectPaymentMethod;
    projectSubscription: ProjectSubscription;
    publicPlans: PublicSubscriptionPlans;
    allAvailablePlans: SubscriptionPlan[];
    allAvailablePrices: SubscriptionPlanPrice[];
    previousProjectSubscription: ProjectSubscription;
    prorationInfo: SubscriptionProration;
};

export type SubscriptionWizardLoadingStates = Record<"publicPlans" | "paymentMethod", "idle" | "loading">;

/**
 * Manages the state and logic of the subscription details updator components (for when a user wants to update/upgrade their subscription)
 */
export class SubscriptionWizardManager {
    private services: SubscriptionWizardServices;

    private history: ReturnType<typeof useHistory>;

    private dataSubject$: BehaviorSubject<SubscriptionWizardData>;

    private _id: string;

    constructor(services: SubscriptionWizardServices, previousProjectSubscription: ProjectSubscription, history: ReturnType<typeof useHistory>) {
        this.services = services;
        this.history = history;
        this._id = uuid();
        const params = new URLSearchParams(window.location.search);
        let newProjectSubscription = { ...previousProjectSubscription };

        // If update is unsuccessful, it means the user has returned after being redirected to the stripe portal.
        // In this instance, we must retrieve the project subscription data form the url
        if (params.get(SUBSCRIPTION_UPDATED_QUERY_NAME) === "false") {
            newProjectSubscription = readSubscriptionFromUrl(previousProjectSubscription);
        }

        this.dataSubject$ = new BehaviorSubject<SubscriptionWizardData>({
            previousProjectSubscription,
            projectSubscription: newProjectSubscription,
            publicPlans: undefined,
            paymentMethod: undefined,
            prorationInfo: undefined,
            allAvailablePrices: undefined,
            allAvailablePlans: undefined,
        });
    }

    public get id(): string {
        return this._id;
    }

    public get data$(): BehaviorSubject<SubscriptionWizardData> {
        return this.dataSubject$;
    }

    private get data(): SubscriptionWizardData {
        return this.data$.value;
    }

    public get selectedPlan(): SubscriptionPlan {
        if (this.data.allAvailablePlans) {
            const plan = this.data.allAvailablePlans.find((p: SubscriptionPlan) => p.id === this.data.projectSubscription.subscriptionPlanId);
            return plan;
        }
        return undefined;
    }

    /**
     * Gets the selected plan price
     * If not found in the current plan, it will check allAvailablePrices
     * If not found in allAvailablePrices, it will get the professional GBP Monthly plan as a fallback
     * This is done because if the user is on a trial, there will be no available prices on the plan, so we set the selected based on the public plans
     */
    public get selectedPlanPrice(): SubscriptionPlanPrice {
        return (
            this.selectedPlan?.subscriptionPlanPrices.find((p) => p.id === this.data.projectSubscription.subscriptionPlanPriceId) ??
            this.data.allAvailablePrices?.find((p) => p.id === this.data.projectSubscription.subscriptionPlanPriceId) ??
            this.data.publicPlans?.professional.subscriptionPlanPrices.find((p) => p.currencyCode === defaultPrice.currencyCode && p.frequency === defaultPrice.frequency)
        );
    }

    public get maximumUsersAllowedForTier(): number {
        return getMaximumUsersAllowedForTier(this.selectedPlan?.name);
    }

    public updateProjectSubscription(projectSubscription: ProjectSubscription): void {
        this.data$.next({ ...this.data, projectSubscription });
    }

    public updateSelectedPlan(plan: SubscriptionPlan) {
        this.data$.next({ ...this.data, projectSubscription: { ...this.data.projectSubscription, subscriptionPlanId: plan.id } });
    }

    public updateCurrency(currencyCode: IsoCurrencyCode) {
        const newPrice = getMatchingPrice(this.selectedPlan.subscriptionPlanPrices, this.data.allAvailablePrices, { ...this.selectedPlanPrice, currencyCode });
        this.data$.next({ ...this.data, projectSubscription: { ...this.data.projectSubscription, subscriptionPlanPriceId: newPrice.id } });
    }

    public updateFrequency(frequency: SubscriptionPlanPriceFrequency) {
        const price = getMatchingPrice(this.selectedPlan.subscriptionPlanPrices, this.data.allAvailablePrices, { ...this.selectedPlanPrice, frequency });
        this.data$.next({ ...this.data, projectSubscription: { ...this.data.projectSubscription, subscriptionPlanPriceId: price.id } });
    }

    public updateTier(tier: SubscriptionPlanTier) {
        const plan = this.data.publicPlans[tier];
        if (plan == null) {
            throw new Error(`Plan does not exist for tier: ${tier}`);
        }
        const price = getMatchingPrice(plan.subscriptionPlanPrices, this.data.allAvailablePrices, this.selectedPlanPrice);
        this.data$.next({ ...this.data, projectSubscription: { ...this.data.projectSubscription, subscriptionPlanId: plan.id, subscriptionPlanPriceId: price.id } });
    }

    public updateProjectSubscriptionField<TField extends keyof ProjectSubscription, TValue extends ProjectSubscription[TField]>(field: TField, value: TValue): void {
        this.updateProjectSubscription({ ...this.data.projectSubscription, [field]: value });
    }

    /**
     *  @deprecated
     * */
    public async getCurrentPaymentMethod() {
        const paymentMethod = await this.services.getCurrentPaymentMethod(this.data.previousProjectSubscription.projectId);
        this.data$.next({ ...this.data, paymentMethod });
        return paymentMethod;
    }

    public async getPublicPlans() {
        if (this.data.publicPlans) {
            return this.data.publicPlans;
        }
        const { publicSubscriptionPlans, allPlans } = await this.services.getPublicPlans(this.data.previousProjectSubscription.projectId);
        this.data$.next({
            ...this.data,
            publicPlans: publicSubscriptionPlans,
            allAvailablePlans: allPlans,
            allAvailablePrices: allPlans.flatMap((p: SubscriptionPlan) => p.subscriptionPlanPrices),
        });
        return publicSubscriptionPlans;
    }

    public async getStripeUrl() {
        const completeParams = new URLSearchParams(window.location.search);
        completeParams.set(SUBSCRIPTION_UPDATED_QUERY_NAME, "true");
        completeParams.delete(VIEW_PLANS_QUERY_NAME);
        completeParams.delete(STAGE_QUERY_NAME);

        const backParams = new URLSearchParams(window.location.search);
        backParams.set(SUBSCRIPTION_UPDATED_QUERY_NAME, "false");
        const projectSubscription: ProjectSubscription = {
            ...this.data.projectSubscription,
            status: this.selectedPlan.name.toLowerCase() !== SubscriptionPlanTier.Trial ? ProjectStatus.Active : this.data.projectSubscription.status,
        };
        Object.entries(projectSubscription).forEach(([key, value]) => {
            let urlValue = value;
            if (typeof value === "object") {
                if (isValidDate(value)) {
                    urlValue = formatISO(value as Date);
                } else if (value == null) {
                    urlValue = null;
                } else {
                    throw new Error(`Value ${value} not supported`);
                }
            }
            backParams.set(key, urlValue);
        });

        // Add parameters for fetching on return
        Object.keys(mockUrlProjectSubscription).forEach((key) => completeParams.set(key, this.data.projectSubscription[key]));
        // On completion, we can assume the project is now active
        completeParams.set("status", ProjectStatus.Active);

        const url = await this.services.getStripeUrl(
            projectSubscription,
            `${window.location.origin + window.location.pathname}?${backParams.toString()}`,
            `${window.location.origin + window.location.pathname}?${completeParams.toString()}`
        );
        return url;
    }

    /**
     *  @deprecated
     * */
    public async getProrationInfo() {
        const res = await this.services.getProrationInfo(
            this.data.projectSubscription.id,
            this.data.projectSubscription.subscriptionPlanPriceId,
            this.data.projectSubscription.maximumUsers
        );
        this.data$.next({ ...this.data, prorationInfo: res });
    }

    public moveToStage(stage: string) {
        // If we navigate to view-plans and the selected plan is different to the current plan, we must revert the changes but keep the price state
        if (stage === subscriptionDetailsStages.viewPlans && this.data.previousProjectSubscription.subscriptionPlanId !== this.data.projectSubscription.subscriptionPlanId) {
            const previousPlanId = this.data.previousProjectSubscription.subscriptionPlanId;
            const priceOnPreviousPlanToMatchCurrentState = getMatchingPrice(
                this.data.publicPlans?.[Object.keys(this.data.publicPlans).find((p) => this.data.publicPlans[p].id === previousPlanId)].subscriptionPlanPrices,
                this.data.allAvailablePrices,
                this.selectedPlanPrice
            );
            this.data$.next({
                ...this.data,
                projectSubscription: {
                    ...this.data.previousProjectSubscription,
                    subscriptionPlanPriceId: priceOnPreviousPlanToMatchCurrentState.id,
                },
            });
        }
        const params = new URLSearchParams(window.location.search);
        params.set(STAGE_QUERY_NAME, stage);
        this.history.push({ search: params.toString() });
    }

    /** Deletes url search params that are no longer needed based on what the current params are */
    public cleanUrlData() {
        const params = new URLSearchParams(this.history.location.search);
        if (params.get(SUBSCRIPTION_UPDATED_QUERY_NAME) === "false") {
            params.forEach((_, key) => {
                if (key in this.data.projectSubscription) {
                    params.delete(key);
                }
            });
            params.delete(SUBSCRIPTION_UPDATED_QUERY_NAME);
        }
        if (params.get(SUBSCRIPTION_UPDATED_QUERY_NAME) === "true") {
            params.delete(SUBSCRIPTION_UPDATED_QUERY_NAME);
        }

        this.history.push({ search: params.toString() });
    }

    public closeSubscriptionDetails() {
        const params = new URLSearchParams(window.location.search);
        params.delete(SUBSCRIPTION_UPDATED_QUERY_NAME);
        Object.keys(this.data.projectSubscription ?? {}).forEach((key) => {
            params.delete(key);
        });

        const stage = params.get(STAGE_QUERY_NAME);
        if ([subscriptionDetailsStages.subscriptionDetails, subscriptionDetailsStages.paymentConfirmation].includes(stage)) {
            if (params.get(VIEW_PLANS_QUERY_NAME) === "true") {
                params.set(STAGE_QUERY_NAME, subscriptionDetailsStages.viewPlans);
            } else {
                params.delete(STAGE_QUERY_NAME);
            }
        }
        this.history.push({ search: params.toString() });
    }

    /**
     *  @deprecated
     * */
    public async updateSubscription() {
        // Update the subscription using the external service
        await this.services.updateSubscription(this.data.projectSubscription, this.selectedPlan);
        // Ensure the previous project subscription is up to date
        this.data$.next({ ...this.data, previousProjectSubscription: this.data.projectSubscription });
    }

    public closeAll() {
        const params = new URLSearchParams(window.location.search);
        params.delete(VIEW_PLANS_QUERY_NAME);
        params.delete(STAGE_QUERY_NAME);
        params.delete(SUBSCRIPTION_UPDATED_QUERY_NAME);
        Object.keys(this.data.projectSubscription ?? {}).forEach((key) => {
            params.delete(key);
        });
        this.history.push({ search: params.toString() });
    }
}

export const SubscriptionDetailsUpdatorContext = createContext<SubscriptionWizardManager>(undefined);

export const useSubscriptionWizard = () => {
    const context = useContext(SubscriptionDetailsUpdatorContext);
    const data = useObservableValue(context.data$, context.data$.value);

    return { context, data: data ?? ({} as SubscriptionWizardData) } as const;
};
