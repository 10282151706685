import { Observable, Subject } from "rxjs";
/* eslint-disable no-param-reassign */
import { ScheduleVersion } from "@iventis/domain-model/model/scheduleVersion";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter } from "rxjs/operators";
import { Node } from "@iventis/domain-model/model/node";
import { Export } from "@iventis/domain-model/model/export";
import { TupleToUnion } from "@iventis/types/useful.types";
import { SocketEvent } from "@iventis/types/loading.types";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { LayerCopyEventPayload } from "@iventis/domain-model/model/layerCopyEventPayload";
import { LayerImportEventPayload } from "@iventis/domain-model/model/layerImportEventPayload";
import { UserNotificationUnion } from "@iventis/notifications";
import { getWsBaseUrl } from "./api";

export const wsUrl = getWsBaseUrl();

// rxjs message subject
export const webSocketMessage = new Subject<AnyInboundSocketMessage>();

/** Gets the socket inbound events matching the given socket event names */
type GetEventsFromNames<TEventName extends SocketEvent[], E = AnyInboundSocketMessage> = E extends { eventName: TupleToUnion<TEventName> } ? E : never;

export const onSocketMessages = <E extends SocketEvent[]>(...events: E): Observable<GetEventsFromNames<E>> =>
    webSocketMessage.pipe(filter<GetEventsFromNames<E>>((msg) => msg && events.includes(msg.eventName)));

// connection actions
export const wsConnect = () => ({ type: "WS_CONNECT", host: wsUrl });
export const wsDisconnect = () => ({ type: "WS_DISCONNECT", host: wsUrl });
export const wsSend = (message: AnyOutboundSocketMessage) => ({ type: "NEW_MESSAGE", payload: { host: wsUrl, message } });

export enum ResourceTypes {
    MAP = "MAP",
    LIBRARY = "LIBRARY",
    SCHEDULE_VERSION = "SCHEDULE_VERSION",
}

export interface ConnectionState {
    internet: { isOnline: boolean };
    connectionStatus: SocketStatus;
}

export type AnyInboundSocketMessage =
    | MapCreatedSocketMessage
    | LayerCreatedSocketMessage
    | MapLayerUpdatedSocketMessage
    | NodeCreatedSocketMessage
    | NodeUpdatedSocketMessage
    | NodeDeletedSocketMessage
    | ScheduleVersionCopiedSocketMessage
    | ExportCompletedSocketMessage
    | ExportFailedSocketMessage
    | UserNotificationSocketMessage
    | LayerImportSocketMessage
    | LayerCopySocketMessage;

export interface MapCreatedSocketMessage {
    eventName: SocketEvent.MAP_CREATED;
    resourceId: string;
    message: { id: string };
}

export interface LayerCreatedSocketMessage {
    eventName: SocketEvent.LAYER_CREATED;
    resourceId: string;
    message: MapLayer;
}

export interface MapLayerUpdatedSocketMessage {
    eventName: SocketEvent.MAP_LAYER_UPDATED;
    resourceId: string;
    message: MapLayer;
}

export interface NodeCreatedSocketMessage {
    eventName: SocketEvent.NODE_CREATED;
    resourceId: string;
    message: Node;
}

export interface NodeUpdatedSocketMessage {
    eventName: SocketEvent.NODE_UPDATED;
    resourceId: string;
    message: Node;
}

export interface NodeDeletedSocketMessage {
    eventName: SocketEvent.NODE_DELETED;
    resourceId: string;
    message: Node;
}

interface ScheduleVersionCopiedMessage {
    existingEntity: ScheduleVersion;
    newEntity: ScheduleVersion;
}

export interface ScheduleVersionCopiedSocketMessage {
    eventName: SocketEvent.SCHEDULE_VERSION_COPIED;
    resourceId: string;
    message: ScheduleVersionCopiedMessage;
}

export interface ExportCompletedSocketMessage {
    eventName: SocketEvent.EXPORT_COMPLETED;
    resourceId: string;
    message: Export;
}

export interface ExportFailedSocketMessage {
    eventName: SocketEvent.EXPORT_FAILED;
    resourceId: string;
    message: Export;
}

export interface UserNotificationSocketMessage {
    eventName: SocketEvent.USER_NOTIFICATION;
    resourceId: string;
    message: UserNotificationUnion;
}

export interface LayerImportSocketMessage {
    eventName: SocketEvent.LAYER_IMPORT;
    resourceId: string;
    message: LayerImportEventPayload;
}

export interface LayerCopySocketMessage {
    eventName: SocketEvent.LAYER_COPY;
    resourceId: string;
    message: LayerCopyEventPayload;
}

export interface AnyOutboundSocketMessage {
    eventName: SocketEvent.RESOURCE_OPENED | SocketEvent.RESOURCE_CLOSED;
    resourceId: string;
    sourceId: string;
    resourceType: ResourceTypes.LIBRARY | ResourceTypes.MAP | ResourceTypes.SCHEDULE_VERSION;
}

export enum SocketStatus {
    CONNECTING = "CONNECTING",
    OPENED = "OPENED",
    CLOSED = "CLOSED",
}

export const initialState: ConnectionState = {
    internet: {
        isOnline: true,
    },
    connectionStatus: SocketStatus.CLOSED,
};

// eslint-disable-next-line import/prefer-default-export
export const connection = createSlice({
    initialState,
    name: "Connection",
    reducers: {
        updateInternetConnection(state: ConnectionState, action: PayloadAction<boolean>) {
            state.internet.isOnline = action.payload;
        },
        updateWsConnection(state: ConnectionState, action: PayloadAction<SocketStatus>) {
            state.connectionStatus = action.payload;
        },
    },
});

export default connection.reducer;
