import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectBillingProvider } from "@iventis/domain-model/model/projectBillingProvider";
import { ProjectPaymentMethod } from "@iventis/domain-model/model/projectPaymentMethod";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { Header4, Header5, Body1, borderRadius, formGap, sectionalMargin, defaultSkeletonMixin, Body2, InteractiveElement, fontSizes, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { capitalise } from "@iventis/utilities";
import { Button, Divider } from "@mui/material";
import { format, formatDuration, intervalToDuration, isBefore } from "date-fns";
import React, { FunctionComponent, useMemo, useState } from "react";

import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import { LoadingComponent } from "@iventis/components";
import { usePaymentDetails } from "./subscription-billing-helpers";
import { ProjectSubscriptionCancellationUpdate, SubscriptionBillingCancelModalComponent } from "./subscription-billing-cancel-modal";

export const SubscriptionBillingPlanDetails: FunctionComponent<{
    isLoadingSubscription: boolean;
    subscription: ProjectSubscription;
    plan: SubscriptionPlan;
    planPrice: SubscriptionPlanPrice;
    changePlanCallback: () => void;
    isPaidSubscription: boolean;
    onSubscriptionCancellationUpdate: (update: ProjectSubscriptionCancellationUpdate) => void;
}> = ({ isLoadingSubscription, subscription, plan, planPrice, changePlanCallback, isPaidSubscription, onSubscriptionCancellationUpdate }) => {
    const translate = useIventisTranslate();
    const { nextPaymentString } = usePaymentDetails(subscription, plan, planPrice);

    const [cancellationModel, setCancellationModal] = useState(false);

    /** Don't show next payment if the current subscription is being cancelled and the commitedUntil and renewalDate are the same */
    const isUserPayingBeforeCancellation = useMemo(() => {
        if (subscription?.cancelAtCommittedUntil) {
            return subscription?.committedUntil?.toDateString() !== subscription?.renewalDate?.toDateString();
        }
        return true;
    }, [subscription?.committedUntil, subscription?.renewalDate, subscription?.cancelAtCommittedUntil]);

    if (subscription == null || plan == null || planPrice == null) {
        return <LoadingComponent />;
    }

    return (
        <>
            <BillingBox>
                {isLoadingSubscription ? (
                    <SubscriptionBillingPlanDetailsSkeleton />
                ) : (
                    <>
                        <Header4>
                            {translate(Content.settings2.billing.subscription_box.title)} ({translate(Content.settings2.billing.subscription_box[subscription?.status])})
                        </Header4>

                        <Header5>{translate(Content.settings2.billing.subscription_box.current_plan)}</Header5>
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            <InlineBox>
                                <Body1>{plan?.name}</Body1>
                                {planPrice?.frequency && (
                                    <>
                                        {isPaidSubscription ? (
                                            <>
                                                <InlineDivider />
                                                <Body1>
                                                    {translate(Content.settings2.billing.subscription_box.paid)}{" "}
                                                    {translate(Content.settings2.subscription_price_breakdown.frequency[planPrice?.frequency]).toLocaleLowerCase()}
                                                </Body1>
                                            </>
                                        ) : (
                                            <>
                                                <InlineDivider />
                                                <Body1>
                                                    {translate(Content.settings2.billing.payment_details_box.expires)} {format(subscription?.renewalDate, "dd MMMM yyyy")}
                                                </Body1>
                                            </>
                                        )}
                                        {subscription?.billingProvider !== ProjectBillingProvider.Stripe && (
                                            <>
                                                <InlineDivider />
                                                <Body1>{translate(Content.settings2.billing.subscription_box.get_in_touch)}</Body1>
                                            </>
                                        )}
                                        {isPaidSubscription && subscription?.billingProvider === ProjectBillingProvider.Stripe && subscription.committedUntil != null && (
                                            <>
                                                <InlineDivider />
                                                <Body1>
                                                    {subscription.cancelAtCommittedUntil ? (
                                                        <span>{translate(Content.subscriptionplan3.cancellation.cancelsOn)}</span>
                                                    ) : (
                                                        <span>{translate(Content.settings2.billing.subscription_box.renews_on)}</span>
                                                    )}
                                                    <span> </span>
                                                    {format(subscription.committedUntil, "PPP")}
                                                </Body1>
                                            </>
                                        )}
                                    </>
                                )}
                            </InlineBox>
                            {subscription?.billingProvider === ProjectBillingProvider.Stripe && (
                                <div className="subscription-buttons">
                                    {/* Subscription is not trial and is active */}
                                    {isPaidSubscription && subscription.status === ProjectStatus.Active && (
                                        <>
                                            {subscription.cancelAtCommittedUntil ? (
                                                <>
                                                    {/* Subscription has been cancelled but cancellation date has not been reached */}
                                                    <Button
                                                        variant="contained"
                                                        onClick={() =>
                                                            onSubscriptionCancellationUpdate({
                                                                cancelAtCommittedUntil: false,
                                                                cancelAdditionalReason: null,
                                                                cancelReason: null,
                                                            })
                                                        }
                                                    >
                                                        {translate(Content.subscriptionplan3.cancellation.reactivate)}
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    {/* Subscription is active */}
                                                    <InteractiveElement className="cancel" onClick={() => setCancellationModal(true)}>
                                                        {translate(Content.subscriptionplan3.cancellation.cancelSubscription)}
                                                    </InteractiveElement>
                                                </>
                                            )}
                                        </>
                                    )}

                                    {!subscription.cancelAtCommittedUntil && (
                                        <Button
                                            disabled={subscription.status === ProjectStatus.InArrears || (subscription.status === ProjectStatus.Cancelled && isPaidSubscription)}
                                            style={{ marginLeft: "auto" }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={changePlanCallback}
                                            data-testid="change-plan-button"
                                        >
                                            {translate(Content.settings2.billing.change_plan)}
                                        </Button>
                                    )}
                                </div>
                            )}
                            {subscription?.billingProvider !== ProjectBillingProvider.Stripe && (
                                <div className="subscription-buttons">
                                    {/* Subscription is not trial and is active */}
                                    {isPaidSubscription && subscription.status === ProjectStatus.Active && (
                                        <>
                                            {subscription.cancelAtCommittedUntil ? (
                                                <>
                                                    <Body1>{translate(Content.subscriptionplan3.cancellation.nonAutomatedReactivate)}</Body1>
                                                </>
                                            ) : (
                                                <>
                                                    <Body1>{translate(Content.subscriptionplan3.cancellation.nonAutomatedCancel)}</Body1>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <Divider />

                        {subscription?.billingProvider === ProjectBillingProvider.Stripe && isPaidSubscription && isUserPayingBeforeCancellation && (
                            <>
                                <Header5>{translate(Content.settings2.billing.subscription_box.next_payment)}</Header5>
                                <InlineBox>
                                    <Body1>{nextPaymentString}</Body1>
                                    {subscription?.renewalDate && isBefore(new Date(), subscription?.renewalDate) && (
                                        <>
                                            <InlineDivider />
                                            <Body1>
                                                {`${format(subscription?.renewalDate, "dd MMMM yyyy")} (${formatDuration(
                                                    intervalToDuration({ start: new Date(), end: subscription?.renewalDate }),
                                                    { format: ["years", "months", "weeks", "days"], delimiter: ", " }
                                                )} ${translate(Content.settings2.billing.subscription_box.days)})`}
                                            </Body1>
                                        </>
                                    )}
                                </InlineBox>
                                <Body2 style={{ marginLeft: sectionalMargin }}>*{translate(Content.settings2.billing.excludes_taxes)}</Body2>
                                <Divider />
                            </>
                        )}
                    </>
                )}
            </BillingBox>
            <SubscriptionBillingCancelModalComponent open={cancellationModel} close={() => setCancellationModal(false)} onSubmit={onSubscriptionCancellationUpdate} />
        </>
    );
};

const PaymentDetailsTitle = ({ translate }) => <Header4>{translate(Content.settings2.billing.payment_details_box.title)}</Header4>;

const PaymentMethodSubTitle = ({ translate }) => <Header5>{translate(Content.settings2.billing.payment_details_box.payment_method)}</Header5>;

export const SubscriptionBillingPaymentDetails: FunctionComponent<{ paymentMethod: ProjectPaymentMethod; redirectCallback: () => void; includeTitle?: boolean }> = ({
    paymentMethod,
    redirectCallback,
    includeTitle = true,
}) => {
    const translate = useIventisTranslate();
    return (
        <BillingBox>
            {includeTitle && <PaymentDetailsTitle translate={translate} />}
            <PaymentMethodSubTitle translate={translate} />
            <InlineBox>
                {!paymentMethod && <Body1>{translate(Content.settings2.billing.payment_details_box.no_payment_method)}</Body1>}
                {paymentMethod?.method === "card" && (
                    <>
                        <FontAwesomeIcon icon={["fas", "credit-card"]} />
                        <Body1>
                            {translate(Content.settings2.billing.payment_details_box.card_ending)} {paymentMethod.lastFourDigits}
                        </Body1>
                        <InlineDivider />
                        <Body1>
                            {translate(Content.settings2.billing.payment_details_box.expires)} {paymentMethod.expiry}
                        </Body1>
                    </>
                )}
                {paymentMethod?.method !== "card" && paymentMethod?.method && (
                    <>
                        <FontAwesomeIcon icon={["fas", "file-invoice-dollar"]} />
                        <Body1>{capitalise(paymentMethod.method)}</Body1>
                    </>
                )}
                {paymentMethod == null ? (
                    <Button style={{ marginLeft: "auto" }} variant="outlined" color="primary" onClick={redirectCallback}>
                        {translate(Content.settings2.subscription_details.add_payment_method)}
                    </Button>
                ) : (
                    <Button style={{ marginLeft: "auto", gap: "5px" }} variant="text" size="large" color="inherit" onClick={redirectCallback}>
                        <FontAwesomeIcon icon={["far", "pen-to-square"]} />
                        {translate(Content.common.buttons.edit)}
                    </Button>
                )}
            </InlineBox>

            {paymentMethod && paymentMethod.billingAddress && (
                <>
                    <Divider />
                    <Header5>{translate(Content.settings2.billing.payment_details_box.billing_address)}</Header5>
                    <InlineBox>
                        <Body1>{paymentMethod.billingAddress}</Body1>
                    </InlineBox>
                    <Divider />
                </>
            )}
        </BillingBox>
    );
};

const InlineDivider = () => <FontAwesomeIcon width="5px" icon={["fas", "circle"]} />;

const InlineBox = styled.div`
    display: flex;
    gap: ${formGap};
    flex-wrap: wrap;
    padding-left: ${sectionalMargin};
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
`;

export const BillingBox = styled.div`
    display: flex;
    flex-direction: column;

    padding: ${sectionalMargin};
    gap: ${formGap};

    border: 1px solid ${({ theme }: { theme: Theme }) => theme.otherColors.separatorDark};

    border-radius: ${borderRadius.standard};

    .subscription-buttons {
        .cancel {
            text-decoration: underline;
            font-size: ${fontSizes.medium};
        }

        display: flex;
        gap: 10px;
        justify-items: flex-end;
        align-items: center;
    }
`;

const SkeletonPlaceholder = styled.span`
    ${defaultSkeletonMixin}
`;

const TitleSkeleton = styled.div`
    height: 1.6em;
    width: 100px;
`;

const TextSkeleton = styled.div`
    height: 1em;
    width: 200px;
`;

export const SubscriptionBillingPlanDetailsSkeleton = () => (
    <>
        <TitleSkeleton>
            <SkeletonPlaceholder />
        </TitleSkeleton>
        <TextSkeleton>
            <SkeletonPlaceholder />
        </TextSkeleton>
        <InlineBox>
            <TextSkeleton>
                <SkeletonPlaceholder />
            </TextSkeleton>
        </InlineBox>
        <Divider />
        <InlineBox>
            <TextSkeleton>
                <SkeletonPlaceholder />
            </TextSkeleton>
        </InlineBox>
    </>
);

export const SubscriptionBillingPaymentDetailsSkeleton: FunctionComponent<{ includeTitle?: boolean }> = ({ includeTitle = true }) => {
    const translate = useIventisTranslate();
    return (
        <>
            <BillingBox>
                {includeTitle && <PaymentDetailsTitle translate={translate} />}
                <PaymentMethodSubTitle translate={translate} />
                <InlineBox>
                    <div style={{ height: "64px", width: "100%" }}>
                        <SkeletonPlaceholder />
                    </div>
                </InlineBox>
            </BillingBox>
        </>
    );
};
