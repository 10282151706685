import { CircularProgress, CircularProgressProps } from "@mui/material";
import React, { FunctionComponent } from "react";
import { styled } from "@iventis/styles";

export const LoadingComponent: FunctionComponent<{ size?: CircularProgressProps["size"]; className?: string }> = ({ size = 40, className }) => (
    <StyledProgressContainer className={className} data-testid="loading-spinner">
        <CircularProgress size={size} />
    </StyledProgressContainer>
);

const StyledProgressContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
