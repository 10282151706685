import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Body1, Body2, Header4, formGap, sectionalMargin, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { FunctionComponent, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { PublicSubscriptionPlans } from "@iventis/domain-model/model/publicSubscriptionPlans";
import { CONTACT_URL } from "@iventis/utilities";
import { Link } from "@mui/material";
import { ProjectBillingProvider } from "@iventis/domain-model/model/projectBillingProvider";
import { ProjectPaymentMethod } from "@iventis/domain-model/model/projectPaymentMethod";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import { ErrorBox } from "@iventis/components";
import { SubscriptionBillingPaymentDetails, SubscriptionBillingPlanDetails, StripeRedirectModal, ProjectSubscriptionCancellationUpdate } from "@iventis/subscriptions";
import { useDispatch, useSelector } from "react-redux";
import { permissionsApi } from "@iventis/api/src/api";
import { IventisErrorBoundaryWithToast } from "@iventis/error-boundaries";
import { toast } from "@iventis/toasts";
import { AuthState, getProjectBillingData, updateProjectSubscription } from "@iventis/permissions/src/auth.slice";
import { useUpgradeSubscriptionWizard } from "@iventis/permissions/src/subscription-wizard";
import { ThunkDispatch, Action } from "@reduxjs/toolkit";

// Children react nodes view plan and subscription upgrade modals only required to be passed in here as child props when using this component at the top of routing.
// This is the case when using the URL projects/:projectid/billing as the users project has expired so they cannot login.
// When using the full project setting component /project-settings/billing, that takes care of the rendering of modals.
export const ProjectBilling: FunctionComponent<PropsWithChildren<{ projectId: string }>> = ({ projectId, children }) => {
    const translate = useIventisTranslate();

    const dispatch = useDispatch<ThunkDispatch<{ auth: AuthState }, typeof getProjectBillingData | typeof updateProjectSubscription, Action<unknown>>>();

    const [modal, setModal] = useState<"redirect" | null>(null);

    const { projectSubscription, subscriptionPlan } = useSelector(({ auth: { projectSubscription, subscriptionPlan, projects, user } }: { auth: AuthState }) => ({
        projectSubscription,
        subscriptionPlan,
        project: projects?.find((p) => p.id === user.projectId),
    }));

    useEffect(() => {
        if (projectSubscription == null || subscriptionPlan == null) {
            dispatch(getProjectBillingData(projectId));
        }
    }, [projectSubscription, subscriptionPlan]);

    const [{ data: paymentMethod }, { data: publicPlans, isLoading: isLoadingPlans }] = useQueries({
        queries: [
            {
                queryKey: ["paymentMethod", projectId],
                queryFn: async () => {
                    const response = await permissionsApi.get<ProjectPaymentMethod>(`projects/${projectId}/payment-methods`);
                    return response.data;
                },
            },
            {
                queryKey: ["publicPlans", "billing", projectId],
                queryFn: async () => {
                    const res = await permissionsApi.get<PublicSubscriptionPlans>("public-subscription-plans");
                    return res.data;
                },
            },
        ],
    });

    const isPaidSubscription = useMemo(() => publicPlans != null && projectSubscription != null && projectSubscription.subscriptionPlanId !== publicPlans.trial.id, [
        projectSubscription,
        publicPlans,
    ]);

    const pricePlan = subscriptionPlan?.subscriptionPlanPrices?.[0];

    const subscriptionWizard = useUpgradeSubscriptionWizard();

    const handleSubscriptionCancelUpdate = (update: ProjectSubscriptionCancellationUpdate) => {
        dispatch(updateProjectSubscription({ subscriptionUpdate: update, projectId, projectSubscriptionId: projectSubscription.id }));
    };

    return (
        <PageContainer>
            <Container>
                <div>
                    <Header4>{translate(Content.settings2.billing.page_title)}</Header4>
                    <Body2>{translate(Content.settings2.billing.page_content)}</Body2>
                </div>
                {projectSubscription?.status === ProjectStatus.InArrears && (
                    <ErrorBox>
                        <ErrorTitleContainer>
                            <FontAwesomeIcon icon={["fas", "circle-exclamation"]} />
                            <Header4>{translate(Content.settings2.billing.in_arrears_error.title)}</Header4>
                        </ErrorTitleContainer>
                        <ErrorBody>{translate(Content.settings2.billing.in_arrears_error.content)}</ErrorBody>
                    </ErrorBox>
                )}
                {projectSubscription?.status === ProjectStatus.Cancelled && isPaidSubscription && (
                    <ErrorBox>
                        <ErrorTitleContainer>
                            <FontAwesomeIcon icon={["fas", "circle-exclamation"]} />
                            <Header4>{translate(Content.settings2.billing.cancelled_error_payed.title)}</Header4>
                        </ErrorTitleContainer>
                        <ErrorBody>
                            {translate(Content.settings2.billing.cancelled_error_payed.content_1)}{" "}
                            <LinkOnError href={CONTACT_URL} target="_blank">
                                {translate(Content.settings2.billing.cancelled_error_payed.content_2)}
                            </LinkOnError>{" "}
                            {translate(Content.settings2.billing.cancelled_error_payed.content_3)}
                        </ErrorBody>
                    </ErrorBox>
                )}
                {projectSubscription?.status === ProjectStatus.Cancelled && !isPaidSubscription && (
                    <ErrorBox>
                        <ErrorTitleContainer>
                            <FontAwesomeIcon icon={["fas", "circle-exclamation"]} />
                            <Header4>{translate(Content.settings2.billing.cancelled_error_trial.title)}</Header4>
                        </ErrorTitleContainer>
                        <ErrorBody>{translate(Content.settings2.billing.cancelled_error_trial.content)}</ErrorBody>
                    </ErrorBox>
                )}
                <BillingErrorBoundary toast={toast}>
                    <SubscriptionBillingPlanDetails
                        subscription={projectSubscription}
                        isLoadingSubscription={isLoadingPlans}
                        plan={subscriptionPlan}
                        planPrice={pricePlan}
                        changePlanCallback={() => subscriptionWizard.open("viewPlans")}
                        isPaidSubscription={isPaidSubscription}
                        onSubscriptionCancellationUpdate={handleSubscriptionCancelUpdate}
                    />
                </BillingErrorBoundary>
                {projectSubscription?.billingProvider === ProjectBillingProvider.Stripe && isPaidSubscription && (
                    <BillingErrorBoundary toast={toast}>
                        <SubscriptionBillingPaymentDetails paymentMethod={paymentMethod} redirectCallback={() => setModal("redirect")} />
                    </BillingErrorBoundary>
                )}
                <IventisErrorBoundaryWithToast toast={toast} size="overlay">
                    <StripeRedirectModal
                        projectId={projectId}
                        open={modal === "redirect"}
                        onClose={() => setModal(null)}
                        getStripeUrl={async () => (await permissionsApi.post<string>(`projects/${projectId}/stripe-customer-portal?returnUrl=${window.location.href}`)).data}
                    />
                </IventisErrorBoundaryWithToast>
                {children}
            </Container>
        </PageContainer>
    );
};

const BillingErrorBoundary = styled(IventisErrorBoundaryWithToast)`
    width: auto;
`;

const Container = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: ${sectionalMargin};
    gap: ${sectionalMargin};
`;

const PageContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;

const LinkOnError = styled(Link)`
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.linkOnError};
    text-decoration: none;
`;

const ErrorTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${formGap};
`;

const ErrorBody = styled(Body1)`
    font-weight: bold;
`;
