/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Theme as IventisTheme } from "@emotion/react";
import { FormControlLabel, Switch as MuiSwitch, Theme, useTheme, createTheme } from "@mui/material";
import { styled } from "@iventis/styles";

/**
 * This component differs from the Custom-formik-switch-button, it's NOT made as a form input,
 * but rather as an interactive customizable element that can have some functionality attached to it.
 */
interface SwitchWithLabelProps {
    width?: string | number;
    buttonPadding?: string; // only receives the shorthand padding property
    label?: string;
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    dataCy?: string;
    disabled?: boolean;
    checkedColour?: string;
    className?: string;
}

export const SwitchWithLabel: React.FunctionComponent<SwitchWithLabelProps> = ({
    width = "100%",
    buttonPadding = "0 0 0 0",
    label = undefined,
    onChange = undefined,
    checked = false,
    dataCy,
    disabled = false,
    checkedColour,
    className,
}) => {
    const muiTheme = createTheme();
    const theme = useTheme<IventisTheme>();
    return (
        <FormControlLabel
            className={className}
            control={
                <Switch
                    muitheme={muiTheme}
                    disabled={disabled}
                    data-cy={dataCy}
                    checked={checked}
                    onChange={onChange}
                    $checkedColour={checkedColour || theme.primaryColors.focus}
                />
            }
            style={{ padding: `${buttonPadding}`, width: `${width}` }}
            label={label}
            sx={label == null && { margin: 0 }}
            labelPlacement="start"
        />
    );
};

/** This uses MUI's theme prop so it can be properly controlled NOT the iventis one.
 *  If you are mocking or using this, be sure to use muiTheme(theme)
 *  See https://mui.com/material-ui/react-switch/#customization for theme
 */
const Switch = styled(MuiSwitch)<{ muitheme: Theme; $checkedColour: string }>`
    width: 45px;
    height: 28px;
    padding: 1.5px;
    margin: ${(props) => props.muitheme.spacing(1)};

    .MuiSwitch-switchBase {
        padding: 4.8px;
        ${(props) => props.checked && `transform: translateX(17px);`};
    }

    .MuiTouchRipple-child {
        background-color: #00000077;
    }

    .MuiSwitch-thumb {
        width: 18px;
        height: 18px;
        color: #fff !important;
    }

    &:focus-within {
        .MuiSwitch-thumb {
            color: ${(props) => props.muitheme.palette.secondary.light};
        }
    }

    .Mui-checked + .MuiSwitch-track {
        background-color: ${(props) => props.color};
        opacity: 1 !important;
        border: none;
    }

    .MuiSwitch-track {
        border-radius: ${26 / 2}px;
        opacity: 1;
        background-color: ${(props) => props.muitheme.palette.grey[100]};
        transition: ${(props) => props.muitheme.transitions.create(["background-color", "border"])};
    }
`;
