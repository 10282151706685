export const STAGE_QUERY_NAME = "stage";
export const VIEW_PLANS_QUERY_NAME = "viewPlans";
export const SUBSCRIPTION_UPDATED_QUERY_NAME = "subscriptionUpdated";
export const subscriptionDetailsStages = {
    viewPlans: "view-plans",
    subscriptionDetails: "subscription-details",
    paymentConfirmation: "payment-confirmation",
};
export const PUBLIC_PLANS_QUERY_KEY = "public-plans";
export const PAYMENT_METHOD_QUERY_KEY = "payment-method";
export const PRORATION_INFO_QUERY_KEY = "proration-info";
