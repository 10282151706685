/* eslint-disable react/destructuring-assignment */
import { Header1, Header3, fontSizes, media, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { planPadding } from "@iventis/styles/src/atomic-rules";
import { Content } from "@iventis/translations";
import { UseIventisTranslate, useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { CONTACT_URL, PRICING_URL, valueToCurrency } from "@iventis/utilities";
import { Button, Link, useTheme } from "@mui/material";
import React, { FunctionComponent, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { IsoCurrencyCode } from "@iventis/domain-model/model/isoCurrencyCode";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import { CurrencySelectorComponent } from "@iventis/components";
import { SubscriptionPlanDetailsCard, SubscriptionPlanDetailsCardSkeleton, SubscriptionPlanTier } from "./subscription-plan-details";
import { FrequencySelector } from "./frequency-selector";
import { useSubscriptionWizard } from "./subscription-wizard-context";
import { PUBLIC_PLANS_QUERY_KEY, subscriptionDetailsStages } from "./subscription-wizard-constants";
import { compareTier, defaultPrice, getMatchingPrice } from "./subscription-wizard-helpers";

export const SubscriptionWizardViewPlansComponent: FunctionComponent = () => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();

    const { context, data } = useSubscriptionWizard();

    const { isLoading } = useQuery([PUBLIC_PLANS_QUERY_KEY, context.id], () => context.getPublicPlans());

    const professionalPrice =
        !isLoading &&
        context.selectedPlan &&
        getMatchingPrice(data.publicPlans?.professional.subscriptionPlanPrices, data.allAvailablePrices, context.selectedPlanPrice ?? defaultPrice);

    const premiumPrice =
        !isLoading &&
        context.selectedPlan &&
        getMatchingPrice(data.publicPlans?.premium.subscriptionPlanPrices, data.allAvailablePrices, context.selectedPlanPrice ?? defaultPrice);

    const availableCurrencies = !isLoading
        ? data.allAvailablePrices.reduce((array, { currencyCode }) => (array.includes(currencyCode) ? array : [...array, currencyCode]), [] as IsoCurrencyCode[])
        : null;

    const existingPlan = useMemo(() => data.allAvailablePlans?.find((p) => p.id === data.previousProjectSubscription?.subscriptionPlanId), [
        data.allAvailablePlans,
        data.previousProjectSubscription,
    ]);

    const isCurrentTier = (tier: SubscriptionPlanTier) => tier.toString().toLowerCase() === existingPlan?.name.toLowerCase();

    return (
        <DialogContentContainer>
            <HeaderContainer>
                <Header>
                    {translate(Content.subscriptionplan.plan_details.title.part_1)}{" "}
                    <em style={{ color: theme.primaryColors.focus, fontStyle: "normal" }}>{translate(Content.subscriptionplan.plan_details.title.part_2)}</em>.
                </Header>
            </HeaderContainer>
            <ControlsContainer>
                <FrequencySelector value={context.selectedPlanPrice?.frequency} onChange={(newFreq) => context.updateFrequency(newFreq)} />
                <CurrencySelectorComponent
                    value={context.selectedPlanPrice?.currencyCode ?? defaultPrice.currencyCode}
                    availableOptions={availableCurrencies}
                    onChange={(newCurr) => context.updateCurrency(newCurr)}
                    disabled={isLoading || data.previousProjectSubscription.status !== ProjectStatus.Trial}
                />
            </ControlsContainer>
            <DetailsContainer>
                {isLoading ? (
                    <DetailsSkeleton />
                ) : (
                    <>
                        <SubscriptionPlanDetailsCard
                            selected={isCurrentTier(SubscriptionPlanTier.Professional)}
                            planTier={SubscriptionPlanTier.Professional}
                            action={
                                <PlanButton
                                    onClick={() => {
                                        context.moveToStage(subscriptionDetailsStages.subscriptionDetails);
                                        context.updateTier(SubscriptionPlanTier.Professional);
                                    }}
                                    variant="contained"
                                    data-testid="upgrade-to-professional"
                                >
                                    {getActionText(SubscriptionPlanTier.Professional, existingPlan, translate)}
                                </PlanButton>
                            }
                            price={valueToCurrency(
                                professionalPrice?.frequency === SubscriptionPlanPriceFrequency.Annually ? professionalPrice?.price / 12 : professionalPrice?.price,
                                context.selectedPlanPrice?.currencyCode ?? defaultPrice.currencyCode,
                                0
                            )}
                        />
                        <SubscriptionPlanDetailsCard
                            selected={isCurrentTier(SubscriptionPlanTier.Premium)}
                            planTier={SubscriptionPlanTier.Premium}
                            action={
                                <PlanButton {...{ target: "_blank" }} href={CONTACT_URL} style={{ backgroundColor: theme.primaryColors.subdued50 }} variant="contained">
                                    {translate(Content.subscriptionplan.plan_details.contact_us)}
                                </PlanButton>
                            }
                            price={valueToCurrency(
                                premiumPrice?.frequency === SubscriptionPlanPriceFrequency.Annually ? premiumPrice?.price / 12 : premiumPrice?.price,
                                context.selectedPlanPrice?.currencyCode ?? defaultPrice.currencyCode,
                                0
                            )}
                        />
                        <SubscriptionPlanDetailsCard
                            selected={isCurrentTier(SubscriptionPlanTier.Enterprise)}
                            planTier={SubscriptionPlanTier.Enterprise}
                            action={
                                <PlanButton {...{ target: "_blank" }} href={CONTACT_URL} style={{ backgroundColor: theme.primaryColors.subdued50 }} variant="contained">
                                    {translate(Content.subscriptionplan.plan_details.contact_us)}
                                </PlanButton>
                            }
                            price={translate(Content.subscriptionplan.plan_details.call_us)}
                        />
                    </>
                )}
            </DetailsContainer>
            <SubContainer>
                <Header3 style={{ fontWeight: "600", color: theme.typographyColors.lessSubdued, textAlign: "center" }}>
                    {translate(Content.subscriptionplan.plan_details.subtitle.part_1)}{" "}
                    <Link target="_blank" href={PRICING_URL}>
                        {translate(Content.subscriptionplan.plan_details.subtitle.part_2)}
                    </Link>{" "}
                    {translate(Content.subscriptionplan.plan_details.subtitle.part_3)}{" "}
                    <Link target="_blank" rel="noopener noreferrer" href={CONTACT_URL}>
                        {translate(Content.subscriptionplan3.cancellation.contactUs)}
                    </Link>
                </Header3>
            </SubContainer>
        </DialogContentContainer>
    );
};

const Header = styled(Header1)`
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    color: ${(props) => props.theme.typographyColors.lessSubdued};

    ${media.extraSmall} {
        font-size: ${fontSizes.xLarge};
    }
`;

/**
 * Takes in a tier and plan and returns the action text, with "Upgrade to" or "Switch to" depending if the tier is lower or higher than the current
 */
const getActionText = (tier: SubscriptionPlanTier, currentPlan: SubscriptionPlan, translate: UseIventisTranslate) => {
    if (tier.toString().toLowerCase() === currentPlan?.name.toLowerCase()) {
        return translate(Content.subscriptionplan.plan_details.manage_plan);
    }

    // If not a public plan, always show switch to
    const isPlanLowerTier = Object.values(SubscriptionPlanTier).includes(currentPlan.name.toLowerCase() as SubscriptionPlanTier)
        ? compareTier(tier, currentPlan?.name.toLowerCase()) < 1
        : true;

    return translate(isPlanLowerTier ? Content.subscriptionplan.plan_details.switch_to : Content.subscriptionplan.plan_details.upgrade_to, {
        plan: translate(Content.subscriptionplan.plan_details.plans[tier].name),
    });
};

const DetailsSkeleton: FunctionComponent = () => (
    <>
        <SubscriptionPlanDetailsCardSkeleton />
        <SubscriptionPlanDetailsCardSkeleton />
        <SubscriptionPlanDetailsCardSkeleton />
        <SubscriptionPlanDetailsCardSkeleton />
    </>
);

const DialogContentContainer = styled.div`
    max-width: 1295px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: ${planPadding.large} 41px;

    ${media.extraSmall} {
        padding: 20px 10px;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: ${planPadding.large};
`;

const SubContainer = styled.div`
    display: flex;
    padding: ${planPadding.small} 0px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
`;

const ControlsContainer = styled.div`
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: flex-start;
    column-gap: 110px;
    row-gap: 15px;
    align-self: stretch;
    flex-wrap: wrap;
`;

const DetailsContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 13.776px 0px;
    align-items: flex-start;
    gap: 21px;
    align-items: stretch;
    flex-wrap: wrap;
`;

const PlanButton = styled(Button)`
    width: 100%;
    height: 61px;
    font-size: ${fontSizes.xLarge};
`;
