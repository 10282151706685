import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { borderRadius, cardShadow, defaultSkeletonMixin, fontSizes, inputHeight, media, narrowPadding, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { planPadding } from "@iventis/styles/src/atomic-rules";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { capitalise } from "@iventis/utilities";
import React, { FunctionComponent, ReactNode } from "react";

import { useTheme } from "@mui/material";

export enum SubscriptionPlanTier {
    Trial = "trial",
    Professional = "professional",
    Premium = "premium",
    Enterprise = "enterprise",
}

type SubscriptionPlanDetailsCardProps = {
    planTier: SubscriptionPlanTier;
    action: ReactNode;
    price: string;
    selected?: boolean;
};

export const SubscriptionPlanDetailsCard: FunctionComponent<SubscriptionPlanDetailsCardProps> = ({ planTier, action, price, selected }) => {
    const theme = useTheme<Theme>();
    const translate = useIventisTranslate();

    return (
        <SubscriptionPlanContainer $selected={selected}>
            <SubscriptionPlanHeaderContainer>
                <Text2>{capitalise(translate(Content.subscriptionplan.plan_details.plans[planTier].name))}</Text2>
                <Text1 style={{ color: theme.otherColors.priceColour }}>{price}</Text1>
                <Text3 style={{ fontWeight: "600" }}>{translate(Content.subscriptionplan.plan_details.per_user)}</Text3>
                {action}
            </SubscriptionPlanHeaderContainer>
            <SubscriptionPlanDetailsContainer>
                <Text3 style={{ fontWeight: "600" }}>
                    {planTier === SubscriptionPlanTier.Professional ? (
                        <span>{translate(Content.subscriptionplan.plan_details.includes)}</span>
                    ) : (
                        <span>
                            {translate(Content.subscriptionplan.plan_details.everything_in, { plan: translate(Content.subscriptionplan.plan_details.plans[planTier].prev_plan) })}
                        </span>
                    )}
                </Text3>
                {Object.keys(Content.subscriptionplan.plan_details.plans[planTier].features).map((x) => (
                    <SubscriptionPlanFeature key={x}>{translate(Content.subscriptionplan.plan_details.plans[planTier].features[x])}</SubscriptionPlanFeature>
                ))}
            </SubscriptionPlanDetailsContainer>
        </SubscriptionPlanContainer>
    );
};

const SubscriptionPlanFeature: FunctionComponent<{ children }> = ({ children }) => {
    const theme = useTheme<Theme>();

    return (
        <div style={{ display: "flex", alignItems: "center", gap: narrowPadding }}>
            <FontAwesomeIcon color={theme.otherColors.priceColour} icon={["far", "check-circle"]} /> <Text3 style={{ fontWeight: "400" }}>{children}</Text3>
        </div>
    );
};

const Text1 = styled.div`
    font-size: ${fontSizes.xxxLarge};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;

    ${media.extraSmall} {
        font-size: ${fontSizes.xxxLarge};
    }
`;

const Text2 = styled.div`
    font-size: ${fontSizes.xxxLarge};
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    ${media.extraSmall} {
        font-size: ${fontSizes.xxLarge};
    }
`;

const Text3 = styled.div`
    font-size: ${fontSizes.medium};
    font-stretch: normal;
    font-style: normal;
`;

export const SubscriptionPlanDetailsCardSkeleton: FunctionComponent = () => (
    <SubscriptionPlanContainer>
        <SubscriptionPlanHeaderContainer>
            <SkeletonPlaceholder style={{ height: fontSizes.xxxLarge, width: "70%" }} />
            <SkeletonPlaceholder style={{ height: "54px", width: "50%" }} />
            <SkeletonPlaceholder style={{ width: "60%" }} />
            <SkeletonPlaceholder style={{ width: "80%", height: inputHeight }} />
        </SubscriptionPlanHeaderContainer>
        <SubscriptionPlanDetailsContainer>
            <SkeletonPlaceholder />
            <SkeletonPlaceholder />
            <SkeletonPlaceholder />
            <SkeletonPlaceholder />
            <SkeletonPlaceholder />
        </SubscriptionPlanDetailsContainer>
    </SubscriptionPlanContainer>
);

const SkeletonPlaceholder = styled.span`
    ${defaultSkeletonMixin}
    height: 20px;
`;

const SubscriptionPlanContainer = styled.div<{ $selected?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${planPadding.small};
    flex: 1 0 0;

    min-width: 300px;

    border-radius: ${borderRadius.input};
    box-shadow: ${cardShadow};
    ${({ $selected, theme }) => $selected && `border: 3px solid ${theme.primaryColors.focus}`};
`;

const SubscriptionPlanDetailsContainer = styled.div`
    display: flex;
    padding: ${planPadding.small};
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
`;

const SubscriptionPlanHeaderContainer = styled.div`
    display: flex;
    padding: ${planPadding.large} ${planPadding.large} 20px ${planPadding.large};
    flex-direction: column;
    align-items: center;
    gap: ${planPadding.small};
    align-self: stretch;
`;
