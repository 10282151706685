import { AxiosInstance } from "axios";
import { Asset } from "@iventis/domain-model/model/asset";
import { AssetCacheService, getExpiryAsISOString } from "@iventis/utilities";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { parseISO } from "date-fns";
import { aggregatePagedResponseLists } from "./api-helpers";

declare module "axios" {
    export interface AxiosRequestConfig {
        suppressErrors?: boolean;
        /** Supresses toasts but still throws errors for try catch statements */
        suppressToasts?: boolean;
    }
}

export const createAssetUrlGetter = (assetsMicroservice: AxiosInstance, assetCacheService: AssetCacheService) => {
    const multipleAssetGetter = (ids: string[]) =>
        assetCacheService.getList(ids, async (unCachedIds) => {
            const { found: assets } = await getAssetsByIds(unCachedIds, assetsMicroservice);
            return assets.map((data) => ({
                url: data?.assetUrl,
                id: data?.id,
                name: data?.name,
                type: data?.type,
                signature: data?.authoritySignature,
                signatureExpiry: getExpiryAsISOString(data?.authoritySignatureExpiry),
                thumbnail: data.thumbnailUrl,
                authorityUrl: data.authorityUrl,
                metaData: data?.metaData,
                updatedAt: data?.updatedAt,
            }));
        });

    const multipleAssetUrlGetter = (allIds: string[]): Promise<{ id: string; url: string; signature: string; name: string; metaData: { sdf: boolean } | null }[]> =>
        multipleAssetGetter(allIds).then((res) =>
            res.map((asset) => ({ id: asset.id, url: `${asset.url}?${asset.signature}`, signature: asset.signature, name: asset.name, metaData: asset.metaData }))
        );

    const assetUrlGetter = (id: string) =>
        assetCacheService
            .get(id, async () => {
                const { data } = await assetsMicroservice.get<Asset>(`/assets/${id}`);
                if (data == null) return undefined;
                const signature = data.authoritySignature;
                return {
                    url: data.assetUrl,
                    id: data.id,
                    name: data.name,
                    type: data.type,
                    signature,
                    signatureExpiry: getExpiryAsISOString(data.authoritySignatureExpiry),
                    thumbnail: data.thumbnailUrl,
                    authorityUrl: data.authorityUrl,
                    metaData: data.metaData,
                    updatedAt: data.updatedAt != null ? parseISO(data.updatedAt.toString()) : null,
                };
            })
            .then((res) => `${res.url}?${res.signature}`);

    const bustCacheIds = (ids: string[]) => assetCacheService.bustCacheIds(ids);
    return {
        assetUrlGetter,
        multipleAssetUrlGetter,
        multipleAssetGetter,
        bustCacheIds,
    };
};

export const getAssetsByIds = async (ids: string[], assetsApi: AxiosInstance, signal?: AbortSignal) => {
    // Exclude any missing ids
    const idsForApi = ids.filter((x) => x);
    const failedIds: string[] = [];
    const results = await Promise.all(
        idsForApi.map(async (id) => {
            try {
                const res = await assetsApi.get<Asset>(`/assets/${id}`, { signal, suppressToasts: true });
                if (res?.data != null) {
                    res.data.updatedAt = res.data.updatedAt != null ? parseISO(res.data.updatedAt.toString()) : null;
                }
                return res?.data;
            } catch {
                failedIds.push(id);
                return undefined;
            }
        })
    );
    return { found: results.filter((a) => a != null), failed: failedIds };
};

export const getAssetsByType = async (types: AssetType[], assetsApi: AxiosInstance, signal) => {
    const assetArrays = await Promise.all(
        types.map(async (type) =>
            aggregatePagedResponseLists<Asset>(async (pageNumber) => {
                const { data } = await assetsApi.post<PagedResult<Asset[]>>(`/assets/filter?pageNumber=${pageNumber}&assetType=${type}`, [], { signal });
                return data;
            })
        )
    );

    return assetArrays.flat();
};
