/* eslint-disable react/destructuring-assignment */
import { IsoCurrencyCode } from "@iventis/domain-model/model/isoCurrencyCode";
import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { Body2, defaultSkeletonMixin, fontSizes, FormLabel, Header5, inputHeight, InteractiveElement, muiInputFormsCSS, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Divider, FormControl, TextField } from "@mui/material";
import format from "date-fns/format";
import { enUS, de, fr } from "date-fns/locale";
import React, { FunctionComponent, ReactNode, useMemo } from "react";

import { ProjectBillingProvider } from "@iventis/domain-model/model/projectBillingProvider";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import i18n from "@iventis/translations/i18n";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import { AutocompleteWithLoading, incrementalInputWidth, IncrementalValueComponent, SupportLink } from "@iventis/components";
import { subscriptionDetailsStages } from "./subscription-wizard-constants";
import { SubscriptionWizardPriceBreakdown } from "./subscription-wizard-price-breakdown";
import { useSubscriptionWizard } from "./subscription-wizard-context";
import { SubscriptionPlanTier } from "./subscription-plan-details";

const locales = { en: enUS, fr, de };

type SubscriptionChange = "trialToMonthly" | "trialToAnnually" | "monthlyToMonthly" | "monthlyToAnnually" | "annuallyToAnnually";

export const SubscriptionWizardDetailsForm: FunctionComponent = () => {
    const translate = useIventisTranslate();
    const { context, data } = useSubscriptionWizard();
    const currencies = useMemo(
        () => context.selectedPlan?.subscriptionPlanPrices.reduce((cum, price) => (cum.includes(price.currencyCode) ? cum : [...cum, price.currencyCode]), [] as IsoCurrencyCode[]),
        [context.selectedPlan?.subscriptionPlanPrices]
    );
    const frequencies = useMemo(
        () =>
            context.selectedPlan?.subscriptionPlanPrices.reduce(
                (cum, price) => (cum.includes(price.frequency) ? cum : [...cum, price.frequency]),
                [] as SubscriptionPlanPriceFrequency[]
            ),
        [context.selectedPlan?.subscriptionPlanPrices]
    );

    const switchingFromAnnual =
        data.allAvailablePrices?.find((p) => data.previousProjectSubscription.subscriptionPlanPriceId === p.id)?.frequency === SubscriptionPlanPriceFrequency.Annually &&
        context.selectedPlanPrice.frequency !== SubscriptionPlanPriceFrequency.Annually;

    const previousPlan = useMemo(() => data.allAvailablePlans.find(({ id }) => id === data.previousProjectSubscription.subscriptionPlanId), [data]);
    const previousPlanPrice = useMemo(() => data.allAvailablePrices.find(({ id }) => id === data.previousProjectSubscription.subscriptionPlanPriceId), [data]);

    const change: SubscriptionChange = useMemo(() => {
        if (previousPlan.name?.toLowerCase() === SubscriptionPlanTier.Trial && context.selectedPlanPrice?.frequency === SubscriptionPlanPriceFrequency.Monthly) {
            return "trialToMonthly";
        }
        if (previousPlan.name?.toLowerCase() === SubscriptionPlanTier.Trial && context.selectedPlanPrice?.frequency === SubscriptionPlanPriceFrequency.Annually) {
            return "trialToAnnually";
        }
        if (previousPlanPrice.frequency === SubscriptionPlanPriceFrequency.Monthly && context.selectedPlanPrice?.frequency === SubscriptionPlanPriceFrequency.Monthly) {
            return "monthlyToMonthly";
        }
        if (previousPlanPrice.frequency === SubscriptionPlanPriceFrequency.Monthly && context.selectedPlanPrice?.frequency === SubscriptionPlanPriceFrequency.Annually) {
            return "monthlyToAnnually";
        }
        if (previousPlanPrice.frequency === SubscriptionPlanPriceFrequency.Annually && context.selectedPlanPrice?.frequency === SubscriptionPlanPriceFrequency.Annually) {
            return "annuallyToAnnually";
        }
        return undefined;
    }, [data, context]);

    return (
        <SubscriptionDetailsUpdatorFormTemplate
            planTypeInput={<TextField disabled value={context.selectedPlan?.name} />}
            currencyInput={
                <AutocompleteWithLoading
                    disableClearable
                    onChange={(x) => context.updateCurrency(x)}
                    value={context.selectedPlanPrice?.currencyCode}
                    options={currencies}
                    loadingOptions={false}
                    getOptionLabel={(x) => x}
                    disabled={data.previousProjectSubscription.status !== ProjectStatus.Trial}
                />
            }
            billFrequencyInput={
                <FrequencyContainer>
                    <AutocompleteWithLoading
                        disableClearable
                        onChange={(x) => context.updateFrequency(x)}
                        value={context.selectedPlanPrice?.frequency}
                        options={frequencies}
                        loadingOptions={false}
                        getOptionLabel={(x) => translate(Content.settings2.subscription_price_breakdown.frequency[x])}
                        error={switchingFromAnnual}
                    />
                    {switchingFromAnnual && (
                        <ErrorText>
                            {translate(Content.settings2.subscription_details.cannot_switch_from_annual)}.{" "}
                            <span>
                                <SupportLink />
                            </span>
                        </ErrorText>
                    )}
                </FrequencyContainer>
            }
            paymentDateInfo={
                (change === "trialToMonthly" || change === "monthlyToMonthly") && (
                    <DateInfo>
                        <Body2>{translate(Content.settings2.subscription_details.payment_date)}</Body2>
                        <Body2>
                            {translate(Content.settings2.subscription_details.payment_date_desc[context.selectedPlanPrice?.frequency], {
                                date: format(change === "trialToMonthly" ? new Date(Date.now()) : data.projectSubscription.renewalDate, "do", {
                                    locale: locales[i18n.language],
                                }),
                            })}
                        </Body2>
                    </DateInfo>
                )
            }
            renewalDateInfo={
                <>
                    {(change === "trialToMonthly" || change === "trialToAnnually") &&
                        (context.selectedPlanPrice?.commitmentDays > 0 || context.selectedPlanPrice?.frequency === SubscriptionPlanPriceFrequency.Annually) && (
                            <DateInfo>
                                <Body2>{translate(Content.settings2.subscription_details.renewal_date)}</Body2>
                                <Body2>
                                    {format(
                                        change === "trialToAnnually" ? addYears(new Date(Date.now()), 1) : addDays(new Date(Date.now()), context.selectedPlanPrice?.commitmentDays),
                                        "PPP"
                                    )}
                                </Body2>
                            </DateInfo>
                        )}
                    {(change === "monthlyToAnnually" || change === "annuallyToAnnually") && (
                        <DateInfo>
                            <Body2>{translate(Content.settings2.subscription_details.renewal_date)}</Body2>
                            <Body2>{format(change === "annuallyToAnnually" ? data.projectSubscription.renewalDate : addYears(new Date(Date.now()), 1), "PPP")}</Body2>
                        </DateInfo>
                    )}
                </>
            }
            addUsersInput={
                <>
                    <IncrementalValueComponent
                        increment={1}
                        minValue={0}
                        maxValue={Infinity}
                        value={data.projectSubscription?.maximumUsers}
                        changeValue={(x) => context.updateProjectSubscriptionField("maximumUsers", x)}
                    />
                    {data.projectSubscription?.maximumUsers > context.maximumUsersAllowedForTier && (
                        <ErrorText>
                            {translate(Content.settings2.subscription_details.exceeded_max_users_message, {
                                numberOfUsers: context.maximumUsersAllowedForTier,
                            })}
                            .{" "}
                            <span>
                                <SupportLink />
                            </span>
                        </ErrorText>
                    )}
                </>
            }
            subscriptionPriceBreakdownComponent={<SubscriptionWizardPriceBreakdown />}
        />
    );
};

const FrequencyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ErrorText = styled(Body2)`
    color: ${(props: { theme: Theme }) => props.theme.secondaryColors.error};
    font-weight: 600;
`;

const SkeletonPlaceholder = styled.span`
    ${defaultSkeletonMixin}
`;

export const SubscriptionDetailsUpdatorFormSkeleton = () => (
    <SubscriptionDetailsUpdatorFormTemplate
        planTypeInput={
            <div style={{ height: inputHeight, width: "100%" }}>
                <SkeletonPlaceholder />
            </div>
        }
        currencyInput={
            <div style={{ height: inputHeight, width: "100%" }}>
                <SkeletonPlaceholder />
            </div>
        }
        billFrequencyInput={
            <div style={{ height: inputHeight, width: "100%" }}>
                <SkeletonPlaceholder />
            </div>
        }
        paymentDateInfo={
            <div style={{ height: "20px", width: "100%" }}>
                <SkeletonPlaceholder />
            </div>
        }
        renewalDateInfo={
            <div style={{ height: "20px", width: "100%" }}>
                <SkeletonPlaceholder />
            </div>
        }
        addUsersInput={
            <div style={{ height: inputHeight, width: incrementalInputWidth }}>
                <SkeletonPlaceholder />
            </div>
        }
        subscriptionPriceBreakdownComponent={
            <div style={{ height: "195px", width: "100%" }}>
                <SkeletonPlaceholder />
            </div>
        }
    />
);

export const SubscriptionDetailsUpdatorFormTemplate: FunctionComponent<{
    planTypeInput: ReactNode;
    currencyInput: ReactNode;
    billFrequencyInput: ReactNode;
    paymentDateInfo: ReactNode;
    addUsersInput: ReactNode;
    subscriptionPriceBreakdownComponent: ReactNode;
    renewalDateInfo: ReactNode;
}> = ({
    planTypeInput,
    currencyInput,
    billFrequencyInput,
    paymentDateInfo: renewalDateInfo,
    addUsersInput,
    subscriptionPriceBreakdownComponent,
    renewalDateInfo: committedUntil,
}) => {
    const translate = useIventisTranslate();
    const { context, data } = useSubscriptionWizard();
    return (
        <Container>
            <div className="section">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Header5 className="sub-heading">{translate(Content.settings2.subscription_details.selected_plan)}</Header5>
                    {data.projectSubscription.billingProvider === ProjectBillingProvider.Stripe && (
                        <ViewPlansButton onClick={() => context.moveToStage(subscriptionDetailsStages.viewPlans)}>
                            <p>{translate(Content.settings2.view_plans)}</p>
                        </ViewPlansButton>
                    )}
                </div>
                <SelectedPlanSection>
                    {/* Plan type */}
                    <FormControl>
                        <FormLabel className="label">{translate(Content.settings2.subscription_details.plan_type)}</FormLabel>
                        {planTypeInput}
                    </FormControl>
                    {/* Currency */}
                    <FormControl>
                        <FormLabel className="label">{translate(Content.settings2.subscription_details.currency)}</FormLabel>
                        {currencyInput}
                    </FormControl>
                    {/* Bill frequency */}
                    <FormControl>
                        <FormLabel className="label">{translate(Content.settings2.subscription_details.billed)}</FormLabel>
                        {billFrequencyInput}
                    </FormControl>
                    {renewalDateInfo}
                    {committedUntil}
                </SelectedPlanSection>
            </div>
            <Divider />
            <div className="section">
                {/* Add users */}
                <Header5 className="sub-heading">{translate(Content.settings2.subscription_details.add_users)}</Header5>
                {addUsersInput}
            </div>
            <Divider />
            <div className="section">
                {/* Price breakdown */}
                {subscriptionPriceBreakdownComponent}
            </div>
        </Container>
    );
};

const ViewPlansButton = styled(InteractiveElement)`
    text-decoration: underline;
    p {
        color: ${(props: { theme: Theme }) => props.theme.primaryColors.focus};
        font-size: ${fontSizes.small};
        font-weight: 600;
        line-height: 20px;
        text-decoration-line: underline;
    }
`;

const SelectedPlanSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Container = styled.div`
    ${muiInputFormsCSS}
    display: flex;
    flex-direction: column;
    gap: 20px;
    .section {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .sub-heading {
        font-weight: 600;
    }
    .label {
        height: 26px;
    }
`;

export const DateInfo = styled.div`
    display: flex;
    justify-content: space-between;
    p:last-child {
        font-weight: 600;
        color: ${(props: { theme: Theme }) => props.theme.typographyColors.subdued};
        line-height: 20px;
    }
`;
