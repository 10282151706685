import { AxiosInstance } from "axios";
import QueryString from "qs";
import { Model } from "@iventis/domain-model/model/model";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import { queryParams } from "@iventis/utilities";
import { Map } from "@iventis/domain-model/model/map";
import { LocalGeoJson, ModelData, ModelLayerStyle } from "@iventis/map-engine";
import { convertMapObjectToLocalGeoJson, isModelLayer } from "@iventis/map-engine/src/utilities/state-helpers";
import { styleTypeToLayerProperties, getStaticAndMappedValues } from "@iventis/map-engine/src/utilities/style-helpers";
import { DataField } from "@iventis/domain-model/model/dataField";
import { Polygon } from "geojson";
import { RouteWaypoint } from "@iventis/domain-model/model/routeWaypoint";
import { aggregatePagedResponse } from "@iventis/api-helpers";
import { Asset } from "@iventis/domain-model/model/asset";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { FilterFormat } from "@iventis/types";

export const getMap = async (
    mapId: string,
    projectId: string,
    mappingApi: AxiosInstance,
    assetsApi: AxiosInstance,
    getWaypoints: (objectId: string, mapId: string) => Promise<RouteWaypoint[]>,
    signal?: AbortSignal
): Promise<{ map: Map; localObjects: LocalGeoJson; projectDataFields: DataField[]; mapObjectBounds: Polygon; models: ModelData[] }> => {
    const mapRequest = mappingApi
        .get<Map>(`/maps/${mapId}`, { signal })
        .catch((e) => {
            throw new Error(e);
        })
        .then((res) => res.data);

    const localOnlyObjectsRequest = getLocalOnlyGeoJson(mappingApi, mapId, [], getWaypoints, signal);

    const projectDataFieldsRequest = mappingApi.get<DataField[]>(`/projects/${projectId}/data-fields`);

    const boundsRequest = getMapObjectBounds(mappingApi, mapId, queryParams.level);

    const [map, localObjects, { data: projectDataFields }, { data: mapObjectBounds }] = await Promise.all([
        mapRequest,
        localOnlyObjectsRequest,
        projectDataFieldsRequest,
        boundsRequest,
    ]);

    const modelsResponse = await multipleModelsGetter(
        map.layers.reduce(
            (ids, layer) => (isModelLayer(layer) ? [...ids, ...getStaticAndMappedValues((layer[styleTypeToLayerProperties[layer.styleType]] as ModelLayerStyle)?.model)] : ids),
            []
        ),
        mappingApi,
        signal
    );

    // For each model which is being used start of getting the glb file now so less time to wait when the map has loaded
    const models = modelsResponse.map((model) => {
        const modelRequest = async () => {
            const assetUrl = await assetUrlGetter(model.lods[0].files[0].assetId, assetsApi);
            const response = await fetch(assetUrl);
            const modelGlb = await response.arrayBuffer();
            return modelGlb;
        };
        return {
            ...model,
            modelRequest: modelRequest(),
        };
    });

    return { map, localObjects, projectDataFields, mapObjectBounds, models };
};

export const getLocalOnlyGeoJson = async (
    mappingApi: AxiosInstance,
    mapId: string,
    additionalFilters: FilterFormat[],
    getWaypoints: (objectId: string, mapId: string) => Promise<RouteWaypoint[]>,
    signal?: AbortSignal
) => {
    // Request for local only map objects (e.g. 3D models)
    const filter = [{ fieldName: "StyleType", operator: IventisFilterOperator.In, value: [StyleType.Model, StyleType.LineModel] }, ...additionalFilters];
    const objects = await getLocalGeoJson(mappingApi, mapId, getWaypoints, filter, signal);
    return objects;
};

export const getLocalGeoJson = async (
    mappingApi: AxiosInstance,
    mapId: string,
    getWaypoints: (objectId: string, mapId: string) => Promise<RouteWaypoint[]>,
    filter: FilterFormat[],
    signal?: AbortSignal
) => {
    const request = {
        params: {
            filter: JSON.stringify(filter),
            mapId,
        },
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: "repeat" }),
    };
    const objects = await aggregatePagedResponse<MapObject[], LocalGeoJson>(mappingApi, `/maps/${mapId}/map_objects/filter`, { ...request, signal }, async (cum, objects) => {
        const waypoints = await Promise.all(
            objects.map<Promise<RouteWaypoint[]>>(async (object) => {
                let waypoints: RouteWaypoint[] | null = null;
                if ((object.modeOfTransport ?? object.geoJsonFeature?.properties?.modeOfTransport) != null) {
                    waypoints = await getWaypoints(object.id, mapId);
                }
                return waypoints;
            })
        );
        return objects
            .map((object, index) => convertMapObjectToLocalGeoJson(object, waypoints[index]))
            .reduce((acc, object) => {
                const { layerid } = object.feature.properties;
                const existingObjects = acc[layerid];
                acc[layerid] = [...(existingObjects ?? []), object];
                return acc;
            }, cum ?? {});
    });
    return objects;
};

/**
 * Gets multiple models by their ids, only one request will be made for each unique id
 *
 * Has to be here due to a circular dependency redux issue
 */
export const multipleModelsGetter = async (ids: string[], mappingApi: AxiosInstance, signal?: AbortSignal) => {
    const models = await mappingApi.get<Model[]>(`models/lods`, {
        // Get all the unique ids so we aren't requesting the same model multiple times
        params: { ids: ids.reduce((acc, id) => (acc.includes(id) ? acc : [...acc, id]), []) },
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: "repeat" }),
        signal,
    });
    return models.data;
};

const assetUrlGetter = async (assetId: string, assetApi: AxiosInstance) => {
    const response = await assetApi.get<Asset>(`/assets/${assetId}`);
    return `${response.data.assetUrl}?${response.data.authoritySignature}`;
};

export async function getMapObjectBounds(mappingApi: AxiosInstance, mapId: string, level: string, signal?: AbortSignal) {
    return mappingApi.get<Polygon>(`/maps/${mapId}/bounding-box?level=${level ?? 0}`, { signal });
}

export async function getCommentBounds(mappingApi: AxiosInstance, mapId: string, level: string, signal?: AbortSignal) {
    return mappingApi.get<Polygon>(`/maps/${mapId}/comments/bounding-box?level=${level ?? 0}`, { signal });
}
