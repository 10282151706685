import { EMPTY_GUID, Optional, getMapObjectNameSystemDatafield, uuidOnlyObjectEntries } from "@iventis/utilities";
import { Node } from "@iventis/domain-model/model/node";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import { feature } from "@turf/helpers";
import { Authorisation } from "@iventis/domain-model/model/authorisation";
import { DataField } from "@iventis/domain-model/model/dataField";
import { Status } from "@iventis/domain-model/model/status";
import { NodeType } from "@iventis/domain-model/model/nodeType";
import {
    COMMENT_ICON_DATA_URL,
    DEFAULT_COMMENT_ICON_VALUE,
    coordinateDeleteSpriteName,
    coordinateHandleSpriteName,
    hatchedPatternBlack12,
    hatchedPatternBlack2,
    hatchedPatternBlack6,
    hatchedPatternRed12,
    hatchedPatternRed2,
    hatchedPatternRed6,
    rotateHandleSpriteName,
    triangleSprintName,
} from "../bridge/constants";
import { CompositionMapObject, TypedFeature } from "../types/internal";
import coordinateHandlePNG from "../assets/coordinate-handle.png";
import rotateHandlePNG from "../assets/rotate-handle.png";
import removeButtonPNG from "../assets/remove-button.png";
import trianglePNG from "../assets/triangle.png";
import hatchedPatternBlack2PNG from "../assets/hatched-pattern-black-2.png";
import hatchedPatternBlack6PNG from "../assets/hatched-pattern-black-6.png";
import hatchedPatternBlack12PNG from "../assets/hatched-pattern-black-12.png";
import hatchedPatternRed2PNG from "../assets/hatched-pattern-red-2.png";
import hatchedPatternRed6PNG from "../assets/hatched-pattern-red-6.png";
import hatchedPatternRed12PNG from "../assets/hatched-pattern-red-12.png";
import { LocalGeoJsonObject } from "../types/store-schema";
import { ObjectUpdate } from "../types/events-store";

export const groupCompositionMapObjectByLayer = (compositionMapObjects: Optional<CompositionMapObject, "geojson">[]) =>
    compositionMapObjects.reduce(
        (cumulative, current) => ({ ...cumulative, [current.layerId]: [...(cumulative[current.layerId] || []), current.geojson] }),
        {} as { [layerId: string]: TypedFeature[] }
    );

export const convertImageIdToLocalPath = (imageId: string) => {
    switch (imageId) {
        case coordinateDeleteSpriteName:
            return removeButtonPNG;
        case coordinateHandleSpriteName:
            return coordinateHandlePNG;
        case rotateHandleSpriteName:
            return rotateHandlePNG;
        case triangleSprintName:
            return trianglePNG;
        case DEFAULT_COMMENT_ICON_VALUE:
            return COMMENT_ICON_DATA_URL;
        case hatchedPatternBlack2:
            return hatchedPatternBlack2PNG;
        case hatchedPatternBlack6:
            return hatchedPatternBlack6PNG;
        case hatchedPatternBlack12:
            return hatchedPatternBlack12PNG;
        case hatchedPatternRed2:
            return hatchedPatternRed2PNG;
        case hatchedPatternRed6:
            return hatchedPatternRed6PNG;
        case hatchedPatternRed12:
            return hatchedPatternRed12PNG;
        default:
            return null;
    }
};

export const convertBlobToBase64 = (blob: Blob): Promise<string> =>
    new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result as string);
    });

export const objectUpdateToNode = (objectUpdate: ObjectUpdate, projectDatafields: DataField[]): Node => {
    const nameDatafield = getMapObjectNameSystemDatafield(projectDatafields);
    const node: Node = {
        authorisation: Authorisation.Write,
        childCount: 0,
        childNodes: [],
        createdAt: new Date(),
        createdByUserId: EMPTY_GUID,
        createdByUserName: "",
        expanded: false,
        favourite: false,
        id: objectUpdate.objectId,
        lastUpdatedAt: new Date(),
        lastUpdatedByUserId: EMPTY_GUID,
        lastUpdatedByUserName: "",
        name: objectUpdate.properties[nameDatafield.id] as string,
        parentId: objectUpdate.layerId,
        sourceId: objectUpdate.objectId,
        status: Status.Active,
        type: NodeType.MapObject,
    };

    return node;
};

export const localGeoJSONObjectToMapObject = (object: LocalGeoJsonObject) => {
    const dataFieldValues = uuidOnlyObjectEntries(object.feature.properties);

    return {
        id: object.objectId,
        layerId: object.feature.properties.layerid,
        layerName: undefined,
        dataFieldValues,
        status: undefined,
        geoJsonFeature: object.feature,
        fixedShape: object.feature.properties.fixedShape,
        modeOfTransport: object.feature.properties.modeOfTransport,
        level: object.feature.properties.level,
    } as MapObject;
};

export const mapObjectUpdateToCompositionMapObject = (mapObjectUpdate: ObjectUpdate): CompositionMapObject => ({
    geojson: feature(mapObjectUpdate.geometry, mapObjectUpdate.properties),
    objectId: mapObjectUpdate.objectId,
    layerId: mapObjectUpdate.layerId,
});
