import { Header2, Header3, fontSizes, media, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { FunctionComponent } from "react";

import { useTheme } from "@mui/material";
import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { SwitchWithLabel } from "@iventis/components/src/custom-switch";

export const FrequencySelector: FunctionComponent<{ onChange?: (value: SubscriptionPlanPriceFrequency) => void; value: SubscriptionPlanPriceFrequency }> = ({
    onChange,
    value,
}) => {
    const translate = useIventisTranslate();
    const theme = useTheme<Theme>();
    return (
        <SelectorContainer>
            <Header>{translate(Content.subscriptionplan.plan_details.frequency.title)}</Header>
            <SwitchContainer>
                <LabelHeader
                    data-testid="frequency-label-monthly"
                    style={{ color: value === SubscriptionPlanPriceFrequency.Annually ? theme.typographyColors.subdued : theme.typographyColors.core }}
                >
                    {translate(Content.subscriptionplan.plan_details.frequency.monthly)}
                </LabelHeader>
                <SwitchWithLabel
                    checked={value === SubscriptionPlanPriceFrequency.Annually}
                    onChange={(_, checked) => onChange(checked ? SubscriptionPlanPriceFrequency.Annually : SubscriptionPlanPriceFrequency.Monthly)}
                    checkedColour={theme.primaryColors.focus}
                />
                <LabelHeader
                    data-testid="frequency-label-annually"
                    style={{ color: value === SubscriptionPlanPriceFrequency.Monthly ? theme.typographyColors.subdued : theme.typographyColors.core }}
                >
                    {translate(Content.subscriptionplan.plan_details.frequency.annually)}
                </LabelHeader>
            </SwitchContainer>
        </SelectorContainer>
    );
};

const Header = styled(Header2)`
    ${media.extraSmall} {
        font-size: ${fontSizes.large};
    }
`;

const LabelHeader = styled(Header3)`
    ${media.extraSmall} {
        font-size: ${fontSizes.large};
    }
`;

const SelectorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
`;

const SwitchContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;
