import { SubscriptionPlanTier } from "@iventis/subscriptions";
import { AnalysisType } from "@iventis/domain-model/model/analysisType";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { SubscriptionPlanFunctionality } from "@iventis/domain-model/model/subscriptionPlanFunctionality";
import { fontSizes, muiInputFormsCSS, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { CONTACT_URL } from "@iventis/utilities";
import { Button } from "@mui/material";
import React, { FunctionComponent } from "react";

import { useUpgradeSubscriptionWizard } from "@iventis/permissions/src/subscription-wizard";

export const getNextTierName = (name: string) => Object.values(SubscriptionPlanTier)[Object.values(SubscriptionPlanTier).findIndex((t) => t === name.toLowerCase()) + 1];

export const UpgradeTextContents: FunctionComponent<{ upgradeType: UpgradeType }> = ({ upgradeType }) => {
    const translate = useIventisTranslate();

    return (
        <>
            <UpgradeText>
                {translate(Content.subscriptionplan2.limits.overlay.content.text1[upgradeType])}{" "}
                <span>
                    <b>{translate(Content.subscriptionplan2.limits.overlay.content.text2)}</b>
                </span>
            </UpgradeText>
            <UpgradeList>
                {Object.values(Content.subscriptionplan2.limits.overlay.content.professional).map((value: string) => (
                    <UpgradeListItem key={value}>{translate(value)}</UpgradeListItem>
                ))}
            </UpgradeList>
        </>
    );
};

export const UpgradeControls = () => {
    const translate = useIventisTranslate();

    const context = useUpgradeSubscriptionWizard();

    return (
        <ControlsContainer>
            <UpgradeButton {...{ target: "_blank" }} href={CONTACT_URL} variant="outlined">
                {translate(Content.settings2.upgrade_plan_warnings.contact_us)}
            </UpgradeButton>
            <UpgradeButton onClick={() => context.open("viewPlans")} variant="contained">
                {translate(Content.settings2.upgrade_plan_warnings.view_plans)}
            </UpgradeButton>
        </ControlsContainer>
    );
};

const ControlsContainer = styled.div`
    ${muiInputFormsCSS}
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    flex-wrap: wrap;
`;

export type UpgradeType = keyof SubscriptionPlanFunctionality | keyof Pick<ProjectSubscription, "maximumUsers"> | keyof typeof AnalysisType;

export const UpgradeButton = styled(Button)`
    width: 200px;
`;

export const UpgradeText = styled.p`
    text-align: center;
    font-size: ${fontSizes.medium};
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

export const UpgradeList = styled.ul`
    text-align: left;
    font-size: ${fontSizes.medium};
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
`;

export const UpgradeListItem = styled.li`
    list-style: disc;
    list-style-position: inside;
`;
