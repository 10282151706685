/* eslint-disable react/jsx-props-no-spreading */
import { Header2, fontSizes, media, narrowPadding, styled } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { FunctionComponent } from "react";
import { IsoCurrencyCode } from "@iventis/domain-model/model/isoCurrencyCode";
import { currencyCodeToSymbol } from "@iventis/utilities";
import { Content } from "@iventis/translations";
import { ChipSelect } from "./chip-select";

export const CurrencySelectorComponent: FunctionComponent<{
    value: IsoCurrencyCode;
    availableOptions?: IsoCurrencyCode[];
    onChange: (value: IsoCurrencyCode) => void;
    disabled?: boolean;
}> = ({ value, availableOptions, onChange, disabled }) => {
    const translate = useIventisTranslate();
    return (
        <Container>
            <Header style={{ fontWeight: "600" }}>{translate(Content.subscriptionplan.plan_details.choose_currency)}</Header>
            <ChipSelect<IsoCurrencyCode>
                value={value}
                options={availableOptions ?? Object.values(IsoCurrencyCode)}
                onChange={onChange}
                renderOption={(value) => `${currencyCodeToSymbol(value)} ${value}`}
                renderLabel={(value) => `${currencyCodeToSymbol(value)} ${value}`}
                disabled={disabled}
            />
        </Container>
    );
};

const Header = styled(Header2)`
    ${media.extraSmall} {
        font-size: ${fontSizes.large};
    }
`;

const Container = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    gap: ${narrowPadding};
`;
