import { StyledCustomDialog } from "@iventis/components";
import {
    STAGE_QUERY_NAME,
    SubscriptionWizardManager,
    SubscriptionDetailsUpdatorContext,
    subscriptionDetailsStages,
    SubscriptionWizardDetails,
    VIEW_PLANS_QUERY_NAME,
    SubscriptionWizardServices,
    SubscriptionWizardViewPlansComponent,
    SUBSCRIPTION_UPDATED_QUERY_NAME,
    useHistory,
} from "@iventis/subscriptions";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { PublicSubscriptionPlans } from "@iventis/domain-model/model/publicSubscriptionPlans";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { toast, ToastType } from "@iventis/toasts";
import { useWindowSize } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useConstant } from "@iventis/utilities";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import qs from "qs";
import { permissionsApi } from "@iventis/api/src/api";
import { AuthState } from "./auth.slice";

/**
 * A multi-step wizard modal that takes the user from:
 *  1. Viewing available plans
 *  2. Modifying subscription details (such as adding users)
 *  3. Modifying or entering their payment details
 */
export const SubscriptionWizard: FunctionComponent = () => {
    const translate = useIventisTranslate();

    // Get the history, project subscription and the services required for the subscription wizard
    const history = useHistory();
    const projectSubscription = useSelector((s: { auth: AuthState }) => s.auth.projectSubscription);
    const services = useServices();

    // Initialise the subscription wizard
    const context = useConstant(() => new SubscriptionWizardManager(services, projectSubscription, history));

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        // If subscription updated is true, it means the user successfully completed the strip form.
        // So before we clear the url, we provide a toast to notify them it was successful
        if (params.get(SUBSCRIPTION_UPDATED_QUERY_NAME) === "true") {
            toast.success({
                type: ToastType.BASIC,
                props: { message: translate(Content.settings2.subscription_details.update_successful), icon: "circle-check" },
            });
        }
        context.cleanUrlData();
    }, []);

    // Check which stage we should be in based on the url params
    const stage = useMemo(() => {
        const params = new URLSearchParams(history.location.search);
        return params.get(STAGE_QUERY_NAME);
    }, [history.location.search]);

    const { screenWidth } = useWindowSize();
    return (
        <SubscriptionDetailsUpdatorContext.Provider value={context}>
            <StyledCustomDialog
                $staticHeight="85vh"
                $screenWidth={screenWidth}
                maxWidth={false}
                open={Object.values(subscriptionDetailsStages).includes(stage)}
                onClose={() => context.closeAll()}
            >
                {stage === subscriptionDetailsStages.viewPlans && <SubscriptionWizardViewPlansComponent />}
                {[subscriptionDetailsStages.subscriptionDetails, subscriptionDetailsStages.paymentConfirmation].includes(stage) && <SubscriptionWizardDetails />}
            </StyledCustomDialog>
        </SubscriptionDetailsUpdatorContext.Provider>
    );
};

const useServices = (): SubscriptionWizardServices => {
    const subscriptionPlan = useSelector((s: { auth: AuthState }) => s.auth.subscriptionPlan);
    return {
        getPublicPlans: async () => {
            const res = await permissionsApi.get<PublicSubscriptionPlans>("public-subscription-plans");

            const plansArray = Object.values(res.data) as SubscriptionPlan[];
            return {
                publicSubscriptionPlans: res.data,
                allPlans: plansArray.some((p: SubscriptionPlan) => p.id === subscriptionPlan.id) ? plansArray : [...plansArray, subscriptionPlan],
            };
        },
        getStripeUrl: async (projectSubscription: ProjectSubscription, backUrl: string, completeUrl: string) =>
            (
                await permissionsApi.post<string>("stripe-update-subscription-portal", null, {
                    params: {
                        backUrl,
                        completeUrl,
                        subscriptionId: projectSubscription.id,
                        priceId: projectSubscription.subscriptionPlanPriceId,
                        quantity: projectSubscription.maximumUsers,
                    },
                    paramsSerializer: (params) => qs.stringify(params),
                })
            ).data,
        /** Not currently in use */
        getCurrentPaymentMethod: async () => Promise.resolve(undefined),
        /** Not currently in use */
        getProrationInfo: async () => Promise.resolve(undefined),
        /** Not currently in use */
        updateSubscription: async () => Promise.resolve(),
    };
};

export const SubscriptionWizardOperator = () => {
    const upgradePlanWizard = useUpgradeSubscriptionWizard();
    return <>{upgradePlanWizard.isOpen && <SubscriptionWizardDetails />}</>;
};

/** A hook that reads from and writes to the url search params to display/close the subscription plan wizard */
export const useUpgradeSubscriptionWizard = () => {
    const history = useHistory();

    // stage = "view-plans" | "subscription-details" | "payment-confirmation" | null
    const stage = useMemo(() => {
        const params = new URLSearchParams(history.location.search);
        return params.get(STAGE_QUERY_NAME);
    }, [history.location.search]);

    // When stage is null, we know it is no longer open
    const isOpen = Object.values(subscriptionDetailsStages).includes(stage);

    // In order to close the modal, we must delete the relevant search params
    const close = () => {
        const params = new URLSearchParams(history.location.search);
        params.delete(STAGE_QUERY_NAME);
        params.delete(VIEW_PLANS_QUERY_NAME);
        history.push({ search: params.toString() });
    };

    // In order to open, we must add the relevant search param
    const open = (stage: keyof typeof subscriptionDetailsStages) => {
        const params = new URLSearchParams(history.location.search);
        params.set(STAGE_QUERY_NAME, subscriptionDetailsStages[stage]);
        if (stage === "viewPlans") {
            params.set(VIEW_PLANS_QUERY_NAME, "true");
        }
        params.delete("upgrade-plan");
        history.push({ search: params.toString() });
    };
    return { isOpen, stage, close, open } as const;
};
