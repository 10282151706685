import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { ProjectBillingProvider } from "@iventis/domain-model/model/projectBillingProvider";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import addMonths from "date-fns/addMonths";
import addYears from "date-fns/addYears";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { IsoCurrencyCode } from "@iventis/domain-model/model/isoCurrencyCode";
import { stringToBool } from "@iventis/utilities";
import { SubscriptionPlanTier } from "./subscription-plan-details";
import { SUBSCRIPTION_UPDATED_QUERY_NAME } from "./subscription-wizard-constants";

export const defaultPrice = { currencyCode: IsoCurrencyCode.GBP, frequency: SubscriptionPlanPriceFrequency.Monthly };

/**
 * Gets the discount to be applied to the price of the projects subscription.
 * @param users Number of users
 * @returns Discount percentage (e.g. 8%)
 */
export const getDiscountsPerUser = (users: number, maximumAllowed: number, tier: SubscriptionPlanTier) => {
    if (![SubscriptionPlanTier.Professional, SubscriptionPlanTier.Premium].includes(tier)) {
        return 0;
    }
    if (users > maximumAllowed) {
        return 0;
    }
    if (users < 5) {
        return 0;
    }
    if (users < 10) {
        return 8;
    }
    if (users < 20) {
        return 10;
    }
    if (users < 50) {
        return 20;
    }
    if (users >= 50) {
        return 40;
    }
    throw new Error("Invalid number of users");
};

/**
 * Given a list of prices, gets the closest matching price to the given partial price
 */
export const getMatchingPrice = (
    prices: SubscriptionPlanPrice[],
    allAvailablePrices: SubscriptionPlanPrice[],
    price: OptionalExceptFor<SubscriptionPlanPrice, "currencyCode" | "frequency">
) => {
    const newPrice = prices.find((p) => p.currencyCode === price.currencyCode && p.frequency === price.frequency);
    if (newPrice) return newPrice;
    return allAvailablePrices.find((p) => p.currencyCode === price.currencyCode && p.frequency === price.frequency);
};

export const compareTier = (nameA: string, nameB: string) => {
    const tiers = Object.keys(SubscriptionPlanTier).map((t) => t.toLowerCase());

    if (!tiers.includes(nameA)) throw new Error("No tier with name of nameA");
    if (!tiers.includes(nameB)) throw new Error("No tier with name of nameB");

    return tiers.findIndex((t) => t === nameA) - tiers.findIndex((t) => t === nameB);
};

/** Returns the maximum users a subscription can have based on the plan's tier */
export const getMaximumUsersAllowedForTier = (tier: string) => {
    switch (tier?.toLowerCase()) {
        case SubscriptionPlanTier.Professional:
        case SubscriptionPlanTier.Premium:
            return 5;
        default:
            return Infinity;
    }
};

export const addByFrequency = (nextRenewalDate: Date, frequency: SubscriptionPlanPriceFrequency, multiplier: number) => {
    switch (frequency) {
        case SubscriptionPlanPriceFrequency.Monthly:
            return addMonths(nextRenewalDate, multiplier);
        case SubscriptionPlanPriceFrequency.Annually:
            return addYears(nextRenewalDate, multiplier);
        default:
            return nextRenewalDate;
    }
};

export const getDateRange = (start: Date, end: Date) => `${toDateRangeFormat(start)}  -  ${toDateRangeFormat(end)}`;

const toDateRangeFormat = (date: Date) => `${format(date, "d")} ${format(date, "MMM")} ${format(date, "y")}`;

export const readSubscriptionFromUrl = <TParams extends [] | [ProjectSubscription]>(
    ...[previousProjectSubscription]: TParams
): TParams extends [ProjectSubscription] ? ProjectSubscription : UrlProjectSubscription => {
    const searchParams = new URLSearchParams(window.location.search);
    const newProjectSubscription = { ...previousProjectSubscription };
    searchParams.forEach((value, key) => {
        if (key in mockCompleteSubscription) {
            const valueType = typeof mockCompleteSubscription[key];
            // Assign the value
            switch (valueType) {
                case "string":
                    newProjectSubscription[key] = value;
                    break;
                case "number":
                    newProjectSubscription[key] = Number(value);
                    break;
                case "boolean":
                    newProjectSubscription[key] = stringToBool(value);
                    break;
                case "object":
                    // The only object type we support in the url is Dates
                    newProjectSubscription[key] = parseISO(value);
                    break;
                default:
                    throw new Error("Value type not suitable for URL");
            }
        }
    });
    return newProjectSubscription;
};

const mockBasicSubscription: ProjectSubscription = {
    id: "",
    billingProvider: ProjectBillingProvider.Stripe,
    subscriptionPlanId: "",
    subscriptionPlanPriceId: "",
    status: ProjectStatus.Trial,
    projectId: "",
    cancelAtCommittedUntil: false,
};

export const mockCompleteSubscription: Required<ProjectSubscription> = {
    ...mockBasicSubscription,
    renewalDate: new Date(),
    maximumUsers: 1,
    committedUntil: new Date(),
};

export type UrlProjectSubscription = Pick<ProjectSubscription, "maximumUsers" | "subscriptionPlanId" | "subscriptionPlanPriceId" | "status">;

export const mockUrlProjectSubscription: UrlProjectSubscription = {
    subscriptionPlanId: "",
    subscriptionPlanPriceId: "",
    maximumUsers: 0,
    status: ProjectStatus.Active,
};

/** Returns true if the latest project subscription is stored in the url */
export const isRemoteSubscriptionInUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get(SUBSCRIPTION_UPDATED_QUERY_NAME) !== "false" && Object.keys(mockUrlProjectSubscription).every((key) => searchParams.has(key))) {
        return true;
    }
    return false;
};

export const isUrlSubscriptionEqual = (a: UrlProjectSubscription, b: UrlProjectSubscription) => Object.keys(mockUrlProjectSubscription).every((key) => a[key] === b[key]);
