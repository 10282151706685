import * as Sentry from "@sentry/react";
import { MetricName, MetricTags } from "./metric-types";
import { getUserTags } from "../user/set-monitoring-user";

/**
 * Increments a counter metric by given amount
 *
 * @param metricName Name of the metric
 * @param tagOptions { tags: Include custom  tags, includeUserTags: Include user tags (userId, userName, projectId etc.) }
 * @param increment Amount to increment the metric by
 */
export function incrementMonitoringCount(metricName: MetricName, tagOptions?: { tags?: MetricTags; includeUserTags?: boolean }, increment = 1) {
    let metricTags = tagOptions?.tags;

    if (tagOptions?.includeUserTags) {
        metricTags = {
            ...metricTags,
            ...getUserTags(),
        };
    }

    Sentry.metrics.increment(metricName, increment, {
        tags: metricTags,
    });
}
