/* eslint-disable react/destructuring-assignment */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Header5, HorizontalGap, InteractiveElement } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Divider } from "@mui/material";
import { valueToCurrency } from "@iventis/utilities";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import React, { FunctionComponent, useState } from "react";
import { Stage } from "@iventis/components";
import { SubscriptionBillingPaymentDetails, SubscriptionBillingPaymentDetailsSkeleton } from "./subscription-billing";
import { StripeRedirectModal } from "./subscription-billing-stripe-redirect-modal";
import { PUBLIC_PLANS_QUERY_KEY, PAYMENT_METHOD_QUERY_KEY, PRORATION_INFO_QUERY_KEY, subscriptionDetailsStages } from "./subscription-wizard-constants";
import { useSubscriptionWizard } from "./subscription-wizard-context";
import { OrderSummaryContainer, OrderSummaryTitle, SubscriptionWizardOrderSummary, SubscriptionWizardOrderSummarySkeleton } from "./subscription-wizard-order-summary";
import { SubscriptionWizardPaymentBreakdown } from "./subscription-wizard-payment-breakdown";

export const SubscriptionWizardPayment: FunctionComponent = () => {
    const [modal, setModal] = useState<"redirect-confirmation" | null>(null);
    const { context, data } = useSubscriptionWizard();
    const isFetchingPlans = useIsFetching([PUBLIC_PLANS_QUERY_KEY, context.id]) > 0;
    const many = useIsFetching([PRORATION_INFO_QUERY_KEY, context.id]);
    const isFetchingProration = many > 0;
    const { fetchStatus: getPaymentMethodStatus } = useQuery([PAYMENT_METHOD_QUERY_KEY, context.id], () => context.getCurrentPaymentMethod(), {
        refetchOnWindowFocus: true,
        // Only fetch the payment details when the plan details are available
        enabled: !isFetchingPlans && !isFetchingProration,
    });
    return (
        <>
            {getPaymentMethodStatus === "idle" ? (
                <SubscriptionBillingPaymentDetails paymentMethod={data.paymentMethod} redirectCallback={() => setModal("redirect-confirmation")} includeTitle={false} />
            ) : (
                <SubscriptionBillingPaymentDetailsSkeleton />
            )}
            <HorizontalGap height="20px" />
            <OrderSummaryContainer>
                <OrderSummaryTitle />
                <Divider />
                {isFetchingPlans || !context.selectedPlan ? <SubscriptionWizardOrderSummarySkeleton /> : <SubscriptionWizardOrderSummary />}
                <SubscriptionWizardPaymentBreakdown />
            </OrderSummaryContainer>
            <StripeRedirectModal
                projectId={data.projectSubscription.projectId}
                open={modal === "redirect-confirmation"}
                onClose={() => setModal(null)}
                getStripeUrl={async () => context.getStripeUrl()}
                additionalQueryKeys={["subscription-wizard"]}
            />
        </>
    );
};

export const CloseSubscriptionDetails = () => {
    const translate = useIventisTranslate();
    const { context } = useSubscriptionWizard();
    return (
        <InteractiveElement onClick={() => context.closeSubscriptionDetails()}>
            <FontAwesomeIcon icon={["far", "xmark"]} />
            <span className="hidden">{translate(Content.common.buttons.close)}</span>
        </InteractiveElement>
    );
};

/** Use when you want a subscription payment stage in your form wizard */
export const useSubscriptionPaymentStage = (back: () => void): Stage => {
    const translate = useIventisTranslate();
    const { context, data } = useSubscriptionWizard();
    return {
        primaryButtonText: translate(Content.settings2.subscription_payment_breakdown.pay, {
            amount: data.prorationInfo == null || data.paymentMethod == null ? "" : valueToCurrency(data?.prorationInfo?.total, context.selectedPlanPrice?.currencyCode, 2),
        }),
        primaryButtonCallback: context.updateSubscription.bind(context),
        secondaryButtons: [
            {
                buttonText: translate(Content.common.buttons.back),
                onButtonPressed: () => {
                    back();
                    context.moveToStage(subscriptionDetailsStages.subscriptionDetails);
                },
            },
        ],
        isValid: data.paymentMethod != null,
        Component: <SubscriptionWizardPayment />,
        dividers: false,
        title: (
            <>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", margin: "0 20px" }}>
                    <Header5>
                        1.{"   "}
                        {translate(Content.settings2.billing.payment_details_box.title)}
                    </Header5>
                    <CloseSubscriptionDetails />
                </div>
                <Divider style={{ margin: "0 20px" }} />
            </>
        ),
    };
};
