import { PopoverOrigin } from "@mui/material";
import { SearchResultGroupInterface } from "@iventis/search/interface/search-result-interface";
import { MapSearchResultType } from "@iventis/search/types/types";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";

export enum MapInterfaceStyle {
    COMPRESSED = "Compressed",
    SPACIOUS = "Spacious",
}

export enum PopoverComponents {
    MAP_SETTINGS = "MAP_SETTINGS",
    VIEW_BY = "VIEW_BY",
    SITEMAP_SELECTOR = "SITEMAP_SELECTOR",
    LEVEL_SELECTOR = "LEVEL_SELECTOR",
    SEARCH = "SEARCH",
}

export type FullScreenSelector = PopoverComponents.LEVEL_SELECTOR | PopoverComponents.SITEMAP_SELECTOR | PopoverComponents.SEARCH;

export interface PopoverPayload {
    component: PopoverComponents;
    props?: { searchResultGroups?: SearchResultGroupInterface<MapSearchResultType>[]; onClose: () => void };
    nodeId: string;
    anchorOrigin: PopoverOrigin;
    transformOrigin: PopoverOrigin;
    translateY?: string;
}

export interface SidebarState {
    mapInterfaceStyle: MapInterfaceStyle;
    popoverOpen: boolean;
    popover: PopoverPayload;
    importLayer: boolean;
    parentFolderId: string;
    fullScreenSelector: FullScreenSelector;
    showImportLayer: boolean;
    content: SidebarContent;
    editingNodeName: boolean;
    modalLayer: { id?: string; anchor?: "sidebar" | "node" };
    attributeId: string;
    attributeLayerId: string;
    previousLibraryId: string;
    layersUndertakingPreviewSnapshots: MapLayer[];
}

export enum SidebarContent {
    LAYERS = "layers",
    DATA_TABLE = "data_table",
    BACKGROUND_MAP = "background_map",
    ANALYSIS = "analysis",
}

/** modalLayerId in sidebar slice is set to this when a new layer is being created */
export const MODAL_NEW_LAYER_ID = "MODAL_NEW_LAYER";
