/* eslint-disable no-param-reassign */
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { isValidUuid } from "@iventis/utilities";
import { BasicMapLayer } from "../types/store-schema";

export function getLayerStyle<TLayer extends BasicMapLayer>(layer: TLayer) {
    switch (layer.styleType) {
        case StyleType.Area:
            return layer.areaStyle;
        case StyleType.Line:
            return layer.lineStyle;
        case StyleType.Point:
            return layer.pointStyle;
        case StyleType.Icon:
            return layer.iconStyle;
        case StyleType.Model:
            return layer.modelStyle;
        case StyleType.LineModel:
            return layer.lineModelStyle;
        default:
            throw new Error("Style could not be found");
    }
}

export const getModelLayerStyle = (layer: BasicMapLayer) => {
    switch (layer.styleType) {
        case StyleType.Model:
            return layer.modelStyle;
        case StyleType.LineModel:
            return layer.lineModelStyle;
        default:
            throw new Error("Style could not be found");
    }
};

export const styleTypeToLayerPropertyMapping = {
    [StyleType.Area]: "areaStyle" as const,
    [StyleType.Line]: "lineStyle" as const,
    [StyleType.Point]: "pointStyle" as const,
    [StyleType.Icon]: "iconStyle" as const,
    [StyleType.Model]: "modelStyle" as const,
    [StyleType.LineModel]: "lineModelStyle" as const,
};

export function setLayerStyle<T = BasicMapLayer>(layer: T, style: LayerStyle): T {
    const newLayer = { ...layer, styleType: style.styleType };
    switch (style.styleType) {
        case StyleType.Area:
            return { ...newLayer, areaStyle: style as AreaStyle };
        case StyleType.Line:
            return { ...newLayer, lineStyle: style as LineStyle };
        case StyleType.Point:
            return { ...newLayer, pointStyle: style as PointStyle };
        case StyleType.Icon:
            return { ...newLayer, iconStyle: style as PointStyle };
        case StyleType.Model:
            return { ...newLayer, modelStyle: style as ModelStyle };
        case StyleType.LineModel:
            return { ...newLayer, lineModelStyle: style as LineModelStyle };
        default:
            throw new Error("style type was not recognised");
    }
}

/**
 * Retrieve the user created layers. This removes any analysis and system layers from the array.
 * @param layers List of layers
 * @returns User layers
 */
export function filterByUserLayers<T extends BasicMapLayer>(layers: T[]): T[] {
    return layers.filter((l) => isValidUuid(l.id));
}
