import { Body1, Header1, SolidButton, formButton, styled } from "@iventis/styles";
import React, { PropsWithChildren } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { Theme } from "@emotion/react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { CONTACT_URL, SUPPORT_EMAIL } from "@iventis/utilities";
import { ErrorImage } from "./error-image";
import { errorToErrorCode } from "./error-code";

export { ErrorBoundary } from "react-error-boundary";

type ErrorCallback = (errorCode: string, error: Error) => void;

export const ErrorBoundaryContext = React.createContext<{
    onError: ErrorCallback;
}>(null);

export type Action = "none" | "reload" | { action: () => void; actionText: string };
export type Size = "large" | "overlay" | "hidden";

export interface ErrorBoundaryProps extends PropsWithChildren {
    action?: Action;
    size?: Size;
    onError?: (errorCode: string, error: Error) => void;
    className?: string;
}

export const IventisErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, action = "none", size = "large", onError, className }) => {
    const errorContext = React.useContext(ErrorBoundaryContext);

    const errorFallback = (error: Error, resetErrorBoundary: () => void) => {
        const errorCode = errorToErrorCode(error);
        onError?.(errorCode, error);
        errorContext?.onError(errorCode, error);
        switch (size) {
            case "hidden":
                return null;
            case "large":
                return <LargeFallback error={error} resetErrorBoundary={resetErrorBoundary} action={action} className={className} />;
            case "overlay":
            default:
                return <OverlayFallback className={className} />;
        }
    };

    return <ErrorBoundary FallbackComponent={({ error, resetErrorBoundary }) => errorFallback(error, resetErrorBoundary)}>{children}</ErrorBoundary>;
};

export interface IventisFallbackProps extends FallbackProps {
    className?: string;
    action?: Action;
}

/**
 * Fallback with SVG Graphic and reload button
 */
export const LargeFallback = ({ error, resetErrorBoundary, className, action }: IventisFallbackProps) => {
    const translate = useIventisTranslate();

    const errorCode = errorToErrorCode(error);

    return (
        <ErrorContainer className={className}>
            <StyledErrorImage />
            <StyledHeader1>{translate(Content.errors.error_boundaries.title_large)}</StyledHeader1>
            <Body1>
                {translate(Content.errors.error_boundaries.error_message)}
                <StyledLink href={`mailto:${SUPPORT_EMAIL}?subject=Error Code: ${errorCode}`}>{SUPPORT_EMAIL}</StyledLink> <br /> <br />
                <strong>{translate(Content.errors.error_boundaries.error_code, { code: errorCode })}</strong>
            </Body1>
            <ButtonContainer>
                <StyledButton href={CONTACT_URL} variant="outlined">
                    {translate(Content.errors.error_boundaries.contact)}
                </StyledButton>
                {action !== "none" && (
                    <StyledButton
                        onClick={() => {
                            if (action === "reload") resetErrorBoundary();
                            else action?.action();
                        }}
                    >
                        {action === "reload" ? <span>{translate(Content.errors.error_boundaries.reload)}</span> : <span>{action.actionText}</span>}
                    </StyledButton>
                )}
            </ButtonContainer>
        </ErrorContainer>
    );
};

const StyledHeader1 = styled(Header1)`
    color: ${({ theme }: { theme: Theme }) => theme.primaryColors.focus};
    font-weight: 600;
`;

const StyledErrorImage = styled(ErrorImage)`
    width: 80%;
    max-width: 333px;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    gap: 20px;
    padding: 40px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

const StyledButton = styled(SolidButton)`
    height: ${formButton.height};
    width: ${formButton.width};
`;

/**
 * Fallback that simply shows a gray background
 */
export const OverlayFallback = ({ className }) => <ErrorOverlay className={className} />;

const ErrorOverlay = styled.div`
    background-color: gray;
    width: 100%;
    height: 100%;
`;

const StyledLink = styled.a`
    color: ${({ theme }: { theme: Theme }) => theme.primaryColors.focus};
    font-weight: 600;
    text-decoration-line: underline;
`;
