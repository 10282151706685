/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Header3 } from "@iventis/styles";
import { Chip, Menu, MenuItem } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ChipSelectProps<T = any> {
    value: T;
    options: T[];
    onChange: (value: T) => void;
    className?: string;
    disabled?: boolean;
    renderOption?: (option) => React.ReactNode;
    renderLabel?: (option) => React.ReactNode;
}

export function ChipSelect<T extends any>({ value, options, onChange, className, disabled, renderOption, renderLabel }: ChipSelectProps<T>) {
    const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<Element>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value?: T) => {
        setAnchorEl(null);
        if (value != null) onChange(value);
    };

    return (
        <>
            <Chip
                className={className}
                label={
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
                        <Header3>{renderLabel ? renderLabel(value) : String(value)}</Header3>
                        {!disabled && <FontAwesomeIcon icon={["fas", anchorEl ? "caret-up" : "caret-down"]} />}
                    </div>
                }
                onClick={(props) => handleClick(props)}
                disabled={disabled}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                    style: {
                        maxHeight: "300px",
                    },
                }}
            >
                {options.map((optionValue) => (
                    <MenuItem key={optionValue.toString()} onClick={() => handleClose(optionValue)} selected={value === optionValue}>
                        {renderOption ? renderOption(optionValue) : String(optionValue)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
