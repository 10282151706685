import { notificationsApi } from "@iventis/api/src/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserNotificationSocketMessage, onSocketMessages } from "@iventis/api/src/connection.slice";
import { NotificationResourceType } from "@iventis/domain-model/model/notificationResourceType";
import { SocketEvent } from "@iventis/types/loading.types";
import { filter } from "rxjs/operators";
import { useEffect, useState } from "react";
import { UserNotificationUnion } from "@iventis/notifications";

const getNotificationsQueryKey = (projectId: string) => `notifications-${projectId}`;
const getNotificationsUnreadCountQueryKey = (projectId: string) => `notifications-unread-count-${projectId}`;

/** Gets all the notifications for the given user and given project and listens to any inbound websocket events for user notifications */
export const useNotifications = (
    userId: string,
    projectId: string | undefined,
    listOpen: boolean,
    isGettingNotificationsAllowed: boolean,
    setNotificationListVisibility: (open: boolean) => void,
    onIncomingNotification: (notification: UserNotificationUnion) => void
) => {
    const queryClient = useQueryClient();
    const [, setLocalNotifications] = useState<UserNotificationUnion[]>([]);

    // All notifications
    const { data: notifications, isInitialLoading } = useQuery({
        queryKey: [getNotificationsQueryKey(projectId)],
        queryFn: async () => {
            const { data: notifications } = await notificationsApi.get<UserNotificationUnion[]>(`/users/${userId}/notifications`);
            const combinedNotifications = notifications;
            setLocalNotifications((localNotifications) => {
                // Ensure that there are no duplicates between the local notifications and the notifications from the server
                const filteredLocalNotifications = localNotifications.filter(
                    (localNotification) => !notifications.some((notification) => notification.id === localNotification.id)
                );
                combinedNotifications.push(...filteredLocalNotifications);
                return filteredLocalNotifications;
            });
            return combinedNotifications?.sort((a, b) => new Date(b.lastUpdatedAt).getTime() - new Date(a.lastUpdatedAt).getTime());
        },
        keepPreviousData: true,
        enabled: listOpen && projectId != null && isGettingNotificationsAllowed,
        retry: false,
    });

    // Notifications unread count
    const { data: notificationsUnread } = useQuery({
        queryKey: [getNotificationsUnreadCountQueryKey(projectId)],
        queryFn: async () => {
            const { data: notificationsUnread } = await notificationsApi.get<number>(`/users/${userId}/notifications/count`);
            return notificationsUnread;
        },
        enabled: projectId != null && isGettingNotificationsAllowed,
        retry: false,
    });

    // Invalidate the notifications and unread count queries
    const invalidateQueries = async () =>
        Promise.all([
            queryClient.invalidateQueries({ queryKey: [getNotificationsQueryKey(projectId)] }),
            queryClient.invalidateQueries({ queryKey: [getNotificationsUnreadCountQueryKey(projectId)] }),
        ]);

    // Update the read status of a user notification
    const { mutateAsync: registerNotificationAsRead } = useMutation({
        mutationFn: async (notificationId: string) => {
            await notificationsApi.patch(`/users/${userId}/notifications/${notificationId}`);
        },
        onSuccess: invalidateQueries,
    });

    const createLocalNotification = (notification: UserNotificationUnion) => {
        setLocalNotifications((n) => [notification, ...n]);
        let alreadyExists = false;
        queryClient.setQueryData<UserNotificationUnion[]>([getNotificationsQueryKey(projectId)], (existingNotifications) => {
            if (existingNotifications != null) {
                const newList = [...existingNotifications];
                const index = newList.findIndex((n) => n.id === notification.id);
                alreadyExists = index !== -1;
                // If the local notification already exists, update it
                if (alreadyExists) {
                    newList[index] = notification;
                    return newList;
                }
                // Else add it to the top of the list
                newList.unshift(notification);
                return newList;
            }
            return existingNotifications;
        });
        // Update the unread count by one
        queryClient.setQueryData<number>([getNotificationsUnreadCountQueryKey(projectId)], (count) => {
            if (alreadyExists) {
                return count;
            }
            if (count == null) {
                return 1;
            }
            return count + 1;
        });
        // When a notification is added, then open the notification list
        setNotificationListVisibility(true);
    };

    // Listen to user notification events
    useEffect(() => {
        const messageStream = onSocketMessages(SocketEvent.USER_NOTIFICATION);
        // Ensure the notification is for the current project
        const subscription = messageStream.pipe(filter<UserNotificationSocketMessage>((msg) => projectId === msg?.message?.projectId)).subscribe(async (msg) => {
            // Layers import is handled elsewehere
            if (msg.message.resourceType === NotificationResourceType.LayersImport) {
                return;
            }
            // Invalidate the queries
            await invalidateQueries();
            onIncomingNotification(msg.message);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return {
        notifications,
        isLoadingList: isInitialLoading,
        notificationsUnread,
        registerNotificationAsRead,
        refreshNotifications: invalidateQueries,
        createLocalNotification,
    } as const;
};
