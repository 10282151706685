/* eslint-disable react/destructuring-assignment */
import { Caption, defaultSkeletonMixin, fontSizes, Header5, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { numberWithCommas } from "@iventis/utilities";
import React, { FunctionComponent, ReactNode } from "react";
import { useIsFetching } from "@tanstack/react-query";
import { useSubscriptionWizard } from "./subscription-wizard-context";
import { PUBLIC_PLANS_QUERY_KEY } from "./subscription-wizard-constants";
import { usePaymentDetails } from "./subscription-billing-helpers";

/**
 * Displays the price breakdown on the chosen plan/price
 * If fetching plans, this component will render a skeleton
 */
export const SubscriptionWizardPriceBreakdown = () => {
    const { context } = useSubscriptionWizard();
    const isFetchingPlans = useIsFetching([PUBLIC_PLANS_QUERY_KEY, context.id]) > 0;
    return <>{isFetchingPlans ? <SubscriptionPriceBreakdownSkeleton /> : <SubscriptionWizardPriceBreakdownWithData />}</>;
};

/**
 * Displays the price breakdown on the chosen plan/price
 */
export const SubscriptionWizardPriceBreakdownWithData: FunctionComponent = () => {
    const translate = useIventisTranslate();
    const { context, data } = useSubscriptionWizard();
    const { currencySymbol, discountTotal, total, discount, billiedAnnually } = usePaymentDetails(data.projectSubscription, context.selectedPlan, context.selectedPlanPrice);
    return (
        <>
            <SubscriptionPriceBreakdownTemplate
                components={{
                    subscriptionFeeValue: (
                        <p style={{ width: "100%", textAlign: "end" }}>
                            {currencySymbol + numberWithCommas(context.selectedPlanPrice.price.toFixed(context.selectedPlanPrice.price % 1 === 0 ? 0 : 2))}
                        </p>
                    ),
                    explanationOfSubscriptionFee: (
                        <p style={{ width: "100%", textAlign: "end" }}>
                            {currencySymbol + context.selectedPlanPrice.price} &times; {data.projectSubscription.maximumUsers}
                            <span> </span>
                            <span>{translate(Content.settings2.subscription_price_breakdown.users)}</span>
                            {billiedAnnually && <span> &times;</span>}
                            {billiedAnnually ? <span>{` 12 ${translate(Content.settings2.subscription_price_breakdown.time_unit.Monthly)}`}</span> : ""}
                        </p>
                    ),
                    discount: <>{discount > 0 && <DiscountComponent currencySymbol={currencySymbol} discount={discount} discountTotal={discountTotal} />}</>,
                    priceBreakdownLabel: (
                        <p>
                            {billiedAnnually ? (
                                <span>
                                    {translate(Content.settings2.subscription_price_breakdown.frequency_proice_breakdown, {
                                        frequency: translate(Content.settings2.subscription_price_breakdown.frequency.Monthly),
                                    })}
                                </span>
                            ) : (
                                <></>
                            )}
                        </p>
                    ),
                    priceBreakdownValue: (
                        <p style={{ width: "100%" }}>
                            {billiedAnnually ? (
                                <>
                                    {currencySymbol + numberWithCommas(Math.round(total / 12).toString())} &times; {12}{" "}
                                    <span>{translate(Content.settings2.subscription_price_breakdown.time_unit.Monthly)}</span>
                                </>
                            ) : (
                                <></>
                            )}
                        </p>
                    ),
                    totalValue: <p style={{ width: "100%" }}>{currencySymbol + numberWithCommas(total.toFixed(total % 1 === 0 ? 0 : 2))}*</p>,
                }}
            />
        </>
    );
};

export const DiscountComponent: FunctionComponent<{
    currencySymbol: ReturnType<typeof usePaymentDetails>["currencySymbol"];
    discount: ReturnType<typeof usePaymentDetails>["discount"];
    discountTotal: ReturnType<typeof usePaymentDetails>["discountTotal"];
}> = ({ currencySymbol, discount, discountTotal }) => {
    const translate = useIventisTranslate();
    return (
        <DiscountAppliedSection>
            <p>{translate(Content.settings2.subscription_price_breakdown.discount_applied)}</p>
            <div>
                {/* Discount */}
                <p style={{ width: "100%" }}>
                    {discount}% <span style={{ color: "#EB0C00" }}>(-{currencySymbol + numberWithCommas(discountTotal.toFixed(2))})</span>
                </p>
                {/* Explanation of discount */}
                <p style={{ color: "#EB0C00" }}>{translate(Content.settings2.subscription_price_breakdown.discount_has_been_applied, { discount: `${discount}%` })}</p>
            </div>
        </DiscountAppliedSection>
    );
};

export const SubscriptionPriceBreakdownSkeleton = () => (
    <SubscriptionPriceBreakdownTemplate
        components={{
            subscriptionFeeValue: TextSkeleton,
            explanationOfSubscriptionFee: TextSkeleton,
            discount: null,
            priceBreakdownLabel: TextSkeleton,
            priceBreakdownValue: TextSkeleton,
            totalValue: TextSkeleton,
        }}
    />
);

const SubscriptionPriceBreakdownTemplate: FunctionComponent<{
    components: Record<"subscriptionFeeValue" | "explanationOfSubscriptionFee" | "priceBreakdownLabel" | "totalValue" | "priceBreakdownValue" | "discount", ReactNode>;
}> = ({ components: { subscriptionFeeValue, explanationOfSubscriptionFee, priceBreakdownLabel, priceBreakdownValue, totalValue, discount } }) => {
    const translate = useIventisTranslate();
    return (
        <>
            <Header5 className="sub-heading">{translate(Content.settings2.subscription_price_breakdown.title)}</Header5>
            <SubscriptionFeeSection>
                <div>
                    <p>{translate(Content.settings2.subscription_price_breakdown.subscription_fee)}</p>
                    <p>{translate(Content.settings2.subscription_price_breakdown.individual_price_breakdown)}</p>
                </div>
                <div>
                    {/* Subscription fee */}
                    {subscriptionFeeValue}
                    {/* Explanation for subscription fee */}
                    {explanationOfSubscriptionFee}
                </div>
            </SubscriptionFeeSection>
            {discount}
            <TotalSection>
                <div>
                    <p>{translate(Content.settings2.subscription_price_breakdown.total)}</p>
                    {priceBreakdownLabel}
                </div>
                <div>
                    {/* Total */}
                    {totalValue}
                    {/* Price breakdown */}
                    {priceBreakdownValue}
                </div>
            </TotalSection>
            <Caption>*{translate(Content.settings2.subscription_price_breakdown.excludes_taxes)}</Caption>
        </>
    );
};

const SubscriptionFeeSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    div:first-child,
    div:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        p:first-child {
            font-size: ${fontSizes.small};
            font-weight: 600;
            line-height: 20px;
        }
        p:last-child {
            font-size: ${fontSizes.small};
            font-weight: 400;
            line-height: 20px;
        }
    }
`;
const DiscountAppliedSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    div:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        text-align: end;
        p:last-child {
            font-size: ${fontSizes.small};
            font-weight: 400;
            line-height: 20px;
        }
    }
    p:first-child,
    div:last-child.p:first-child {
        font-size: ${fontSizes.small};
        font-weight: 600;
        line-height: 20px;
    }
`;

const TotalSection = styled.div`
    display: flex;
    padding: 5px 0px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    div:first-child,
    div:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        text-align: end;
        p:first-child {
            font-size: ${fontSizes.large};
            font-weight: 900;
        }
        p:last-child {
            font-size: ${fontSizes.small};
            font-weight: 400;
            line-height: 20px;
        }
    }
`;

const SkeletonPlaceholder = styled.span`
    ${defaultSkeletonMixin}
`;

const TextSkeleton = <SkeletonPlaceholder style={{ height: "20px", width: "75px" }} />;
