/* eslint-disable react/destructuring-assignment */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Header5, media, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { CONTACT_URL } from "@iventis/utilities";
import React, { FunctionComponent, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { FormWizardTemplate, Stage } from "@iventis/components";
import { PUBLIC_PLANS_QUERY_KEY } from "./subscription-wizard-constants";
import { useSubscriptionWizard } from "./subscription-wizard-context";
import { CloseSubscriptionDetails } from "./subscription-wizard-details-payment";
import { SubscriptionWizardDetailsForm, SubscriptionDetailsUpdatorFormSkeleton } from "./subscription-wizard-details-form";
import { StripeRedirectModal } from "./subscription-billing-stripe-redirect-modal";

export const SubscriptionWizardDetails: FunctionComponent = () => {
    const translate = useIventisTranslate();

    const { context, data } = useSubscriptionWizard();

    const { fetchStatus: getPublicPlansStatus } = useQuery([PUBLIC_PLANS_QUERY_KEY, context.id], () => context.getPublicPlans());

    const [modal, setModal] = useState<"redirect-confirmation" | null>(null);

    const switchingFromAnnual =
        data.allAvailablePrices?.find((p) => data.previousProjectSubscription.subscriptionPlanPriceId === p.id)?.frequency === SubscriptionPlanPriceFrequency.Annually &&
        context.selectedPlanPrice.frequency !== SubscriptionPlanPriceFrequency.Annually;

    const changedPlan =
        data.projectSubscription.subscriptionPlanPriceId !== data.previousProjectSubscription.subscriptionPlanPriceId ||
        data.projectSubscription?.maximumUsers !== data.previousProjectSubscription.maximumUsers;

    const stages: Stage[] = [
        {
            primaryButtonText: translate(Content.settings2.subscription_details.continue_to_payment),
            primaryButtonCallback: () => setModal("redirect-confirmation"),
            secondaryButtons: [{ buttonText: translate(Content.settings2.upgrade_plan_warnings.contact_us), onButtonPressed: () => window.open(CONTACT_URL) }],
            isValid:
                getPublicPlansStatus === "idle" &&
                data.projectSubscription?.maximumUsers != null &&
                data.projectSubscription?.maximumUsers <= context.maximumUsersAllowedForTier &&
                changedPlan &&
                !switchingFromAnnual,
            Component: getPublicPlansStatus === "idle" ? <SubscriptionWizardDetailsForm /> : <SubscriptionDetailsUpdatorFormSkeleton />,
        },
    ];
    return (
        <StyledContainer>
            <FormWizardTemplate classNameContent="subscription-details" title={<SubscriptionDetailsTitle />} currentStage={0} stages={stages} />
            <StripeRedirectModal
                projectId={data.projectSubscription.projectId}
                open={modal === "redirect-confirmation"}
                onClose={() => setModal(null)}
                getStripeUrl={async () => context.getStripeUrl()}
                additionalQueryKeys={["subscription-wizard"]}
                message={translate(Content.settings2.subscription_details.redirect_modal.message)}
                title={translate(Content.settings2.subscription_details.redirect_modal.title)}
                openNewTab={false}
            />
        </StyledContainer>
    );
};

const SubscriptionDetailsTitle = () => {
    const translate = useIventisTranslate();
    return (
        <div style={{ display: "flex", gap: "8px", alignItems: "center", margin: "20px" }}>
            <FontAwesomeIcon icon={["far", "users"]} />
            <Header5 style={{ flexGrow: 1 }}>{translate(Content.settings2.subscription_details.title)}</Header5>
            <CloseSubscriptionDetails />
        </div>
    );
};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 500px;
    ${media.extraSmall} {
        width: auto;
    }
`;
