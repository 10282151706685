/* eslint-disable no-console */
import { StyleType } from "@iventis/domain-model/model/styleType";
import { incrementMonitoringCount } from "../set-monitoring-metric";
import { MetricName, MetricTagName } from "../metric-types";

export function layerCreationMonitoringMetric(
    layerType: StyleType,
    tags: { [MetricTagName.MAP_ID]: string; [MetricTagName.LAYER_ID]: string; [MetricTagName.LAYER_NAME]: string }
) {
    switch (layerType) {
        case StyleType.Area:
            incrementMonitoringCount(MetricName.AREA_LAYER_CREATED, { tags, includeUserTags: true });
            break;
        case StyleType.Line:
            incrementMonitoringCount(MetricName.LINE_LAYER_CREATED, { tags, includeUserTags: true });
            break;
        case StyleType.Point:
            incrementMonitoringCount(MetricName.POINT_LAYER_CREATED, { tags, includeUserTags: true });
            break;
        case StyleType.Icon:
            incrementMonitoringCount(MetricName.ICON_LAYER_CREATED, { tags, includeUserTags: true });
            break;
        case StyleType.Model:
            incrementMonitoringCount(MetricName.MODEL_LAYER_CREATED, { tags, includeUserTags: true });
            break;
        case StyleType.LineModel:
            incrementMonitoringCount(MetricName.LINE_MODEL_LAYER_CREATED, { tags, includeUserTags: true });
            break;
        default:
            console.warn(`Layer type ${layerType} is not supported for monitoring`);
    }
}
